import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { SavedPayment, YCCommercePayments } from '@yc-commerce/payments-frontend-sdk';
import {
  Cart,
  CartFee,
  PaymentForm,
  DeliveryAddress
} from '@pizza-hut-us-development/client-core';

import { FormChangeEvent } from '@/common/eventTypes';
import { ContactlessCurbsideInfo } from '@/checkout/types';
import { CreditCardInfo } from '@/domain/payments/types';

export type UsePaymentBreakdown = [
  {
    total: string;
    subtotal: string;
    tax: string | number;
    fees: CartFee[];
    discountedAmount: string;
    displayableTip: string;
    tipLabel: string;
    ariaTipLabel: string;
    totalGiftCardsAmount: string;
    isTipLineItemDisabled: boolean;
  },
  {
    handleFeeClick: () => void;
  }
];

export type UseContactInfo = [
  {
    showPromotionsCheckbox: boolean;
    checkBox: boolean;
    isDelivery: boolean;
    showSignIn: boolean;
    emailInputDisabled: boolean;
  },
  {
    register: UseFormMethods['register'];
    errors: UseFormMethods['errors'];
    setPhone: (phoneInput: string) => void;
    handlePhoneChange: () => void;
    handleCheckbox: () => void;
    startIdleTimeout: () => void;
    handleSignInRail: () => void;
    handleEmailChange: () => void;
  }
];

export type UseDeliveryInstructions = [
  {
    showDeliveryInstructions: boolean;
  },
  {
    handleOnClick: () => void;
    handleChange: (value: string) => void;
    CaretDirection: () => JSX.Element;
  }
];

// ScheduleForLater

export type DateTimeEntry = {
  timeLabel: string;
  completeDateValue: string;
};

export interface ScheduleDate {
  label: string;
  value: string;
  dayValue: number;
}

export interface ScheduleDateTime extends ScheduleDate {
  dateTimeEntries: DateTimeEntry[];
}

export type UseScheduleForLater = [
  {
    dateSelectLabel: string;
    dateSelectAriaLabel: string;
    timeSelectLabel: string;
    timeSelectAriaLabel: string;
    scheduleForLaterDays: ScheduleDateTime[] | undefined;
    isStoreOpen: boolean;
    timeSlots: DateTimeEntry[];
    isScheduleForLaterExpanded: boolean;
    hasTimeError: boolean;
    timeErrorContent: string;
  },
  {
    handleOnClick: () => void;
    handleDateChange: (event: FormChangeEvent, name: string) => void;
    handleTimeChange: (event: FormChangeEvent, name: string) => void;
    handleTimeEmpty: (event: FormChangeEvent) => void;
  }
];

export type UseContactlessDelivery = [
  {
    isChecked: boolean;
  },
  {
    handleChange: (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => void;
  }
];

export type UseContactlessCurbside = [
  {
    userWantsContactlessCurbside: boolean;
    contactlessCurbsideErrors: ContactlessCurbsideInfo;
  },
  {
    handleCheckboxChange: () => void;
    onTextInputFocus: () => void;
    onTextInputBlur: () => void;
    onTextEdit: (newText: string) => void;
    getDescriptionHelperText: () => string | undefined;
    startIdleTimeout: () => void;
  }
];

export type UseOccasionDetails = [
  {
    isDelivery: boolean;
    title: string;
    shouldShowContactlessCurbside: boolean;
    shouldShowContactlessPickup: boolean;
    shouldShowContactlessDelivery: boolean;
    showTrackYourOrder?: boolean;
  },
  {
    changeLocalizedStore: () => void;
  }
];

export type UseYourOrder = [
  {
    itemsInCart: string;
  },
  {
    handleOnClick: () => void;
  }
];

export type UsePlaceOrderProps = {
  sdk: YCCommercePayments | null;
  formMethods: UseFormMethods<{
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }>;
};
export type HandlePlaceOrder = (
  formValues: UsePlaceOrderInput,
) => Promise<void>;

export enum PaymentTypes {
  ApplePay = 'Pay',
  CreditDebit = 'Credit/Debit',
  SavedCard = 'SavedCreditcard',
  Cash = 'Cash',
  GiftCard = 'GiftCard',
  NotImplemented = 'NotImplemented'
}

export enum OccasionCode {
  DELIVERY = 'D',
  CARRYOUT = 'C'
}

export type YumPaymentSDK = {
  yumToken?: string;
};

export type GuestToUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type GuestToUserProps = {
  yumIframeRef: React.RefObject<HTMLDivElement>;
  userFormValues: GuestToUserFormData;
};

export type UseGuestToUser = (formData: GuestToUserFormData) => {
  isLoaded: boolean;
};

export type UseHutRewards = [
  {
    userWantsHutRewards: boolean;
    optOutURL: string;
  },
  {
    handleCheckboxChange: () => void;
  }
];

export type UseCheckoutType = [
  {
    formRef: MutableRefObject<HTMLFormElement | null>;
    formMethods: UseFormMethods<{
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    }>;
    buttonTextSubmit: string;
    showHutRewards: boolean;
    formValues: GuestToUserFormData;
    sdk: React.MutableRefObject<YCCommercePayments | null>;
    yumIframeContainerRef: React.MutableRefObject<HTMLDivElement | null>;
    showSkeleton:boolean;
  },
  {
    submitCheckout: () => void;
    updateSavedExistingAccounts: (account: string) => void;
  }
];

export type UsePaymentOptions = [
  {
    availablePaymentTypes: PaymentTypes[];
    isCCTabActive: boolean;
    occasion: OccasionChoice;
    isYumEcomm: boolean;
    showCashPayment: boolean;
    isLoggedIn: boolean;
  },
  {
    onSelectPaymentType: (value: PaymentTypes) => void;
    isActiveTab: (tab: PaymentTypes) => boolean;
  }
];

export type YumPaymentIframePageType = 'GUEST_CHECKOUT' | 'USER_CHECKOUT' | 'USER_SAVE_NEW_CARD';

export type UseYumPaymentIframeProps = {
  sdk?: YCCommercePayments;
  pageType?: YumPaymentIframePageType;
};

type YumPaymentIframeStyle =
  | {
    opacity: number;
    height: number;
  }
  | {
    opacity?: undefined;
    height?: undefined;
  };

export type UseYumPaymentIframe = [
  {
    captureCardDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
    iframeStyle: YumPaymentIframeStyle;
    isLoading: boolean;
  }
];

export type UseAcceptedCreditCardBrands = [
  {
    voiceoverMessage: string;
    acceptedCCTypesForOccasion: AcceptedCCTypes[];
  }
];

export type CreditInfo = {
  cardNumber: string;
  nameOnCard?: string;
  cvvCode?: string;
  expiration?: string;
  billingZip?: string;
};

export type ScheduleForLaterInfo = {
  date: string;
  approximateDateTime: string | Date;
};

export type CustomerCheckoutInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  promotionConsent?: boolean;
  creditDebitInfo?: CreditInfo;
  scheduleForLater?: ScheduleForLaterInfo;
  contactlessCurbside?: ContactlessCurbsideInfo;
  contactlessDelivery?: boolean;
  windowPickup?: boolean;
  deliveryAddress?: DeliveryAddress;
  deliveryInstructions?: string;
  smsPhone?: string;
  trackOrder?: boolean;
};

export type UsePlaceOrderInput = {
  creditDebitInfo?: CreditInfo;
  futureOrderTime?: string;
  smsPhone?: string;
} & CustomerCheckoutInfo;

export type UseCheckoutProps = YumPaymentSDK;

export type HutRewardsProps = {
  formValues: GuestToUserFormData;
  yumIframeRef: React.MutableRefObject<HTMLDivElement | null>;
};

export interface CheckoutProviderType {
  activePaymentType: PaymentTypes;
  setActivePaymentType: (value: PaymentTypes) => void;
  tokenizedYumCardNumber: null | string;
  setTokenizedYumCardNumber: (value: string | null) => void;
  savedCreditCard: CreditCardInfo | undefined;
  setSavedCreditCard: (value: CreditCardInfo | undefined) => void;
  yumPaymentError: boolean;
  setYumPaymentError: (value: boolean) => void;
  checkoutBtnLoading: boolean;
  setCheckoutBtnLoading: (value: boolean) => void;
  backupCardNumber: string;
  setBackupCardNumber: (param: string) => void;
  cardExpiration: string;
  setCardExpiration: (param: string) => void;
  isContactlessDelivery: boolean;
  setIsContactlessDelivery: Dispatch<SetStateAction<boolean>>;
  isContactlessCurbside: boolean;
  setIsContactlessCurbside: Dispatch<SetStateAction<boolean>>;
  deliveryInstructions: string;
  setDeliveryInstructions: Dispatch<SetStateAction<string>>;
  futureOrderTime: string;
  setFutureOrderTime: (newVal: string) => void;
  isScheduleForLaterExpanded:boolean;
  setIsScheduleForLaterExpanded:(newVal: boolean) => void;
  startIdleTimeout: () => void;
  shouldResetForm: boolean;
  paymentFormLoading: boolean;
  setPaymentFormLoading: (value: boolean) => void;
  yumPaymentSessionStarted: boolean;
  setYumPaymentSessionStarted: (value: boolean) => void;
  savedYumPaymentMethods: SavedPayment[];
  setSavedYumPaymentMethods: (savedPaymentMethods: SavedPayment[]) => void;
  savedYumCreditCard: SavedPayment | undefined;
  setSavedYumCreditCard: (savedCreditCard: SavedPayment | undefined) => void;
}

export interface CheckoutProviderProps {
  children: JSX.Element;
}

export enum Occasion {
  DELIVERY = 'Delivery',
  CARRYOUT = 'Carryout',
  NOTAVAILABLE = ''
}

export type BuildOrderPayloadInput = {
  deliveryInstructions: string;
  formsOfPayment: PaymentForm[];
  formValues: UsePlaceOrderInput;
  isContactlessCurbside?: boolean;
  isContactlessDelivery?: boolean;
  cart?: Cart;
};

export enum GiftCardTypes {
  None = 'None',
  ThirdParty = 'ThirdParty',
  Legacy = 'Legacy'
}

export enum PlaceOrderPaymentTypes {
  ApplePay = 'pay',
  CreditDebit = 'creditcard',
  Cash = 'cash',
  GiftCard = 'giftcard',
  ThirdParty = 'tpgc'
}

export interface ApplyableTPGC {
  cardCode: string;
  cardMask: string;
  expiration: string;
  gateway: string;
  postalCode: string;
  token: string;
}

export interface ApplyablePHGiftCard {
  number?: string;
  pin?: string;
  paymentId?: string;
}

export interface ApplyableGiftCard {
  number: string;
  balance: number;
  appliedAmount: number;
  type: string;
  metadata: ApplyablePHGiftCard | ApplyableTPGC;
  savedInfo?: {
    paymentId: string;
    type?: GiftCardTypes.Legacy | GiftCardTypes.ThirdParty;
  };
}

export interface GiftCardBalanceInformation {
  amountApplied: string;
  remainingBalance: string;
  balance: number;
  cardLastFour: string;
  isDisabled: boolean;
}

export const TPGC_GATEWAY = 'VERIFONE';

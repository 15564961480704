import SelectedPin from './icons/SelectedPin/selectedPin.png';
import Pin from './icons/Pin/pin.png';

export default function getMarkers(
  stores: Array<StoreDetail>,
  onClickHandler: Function,
  highlightedMarker: string
): any {
  const { LatLngBounds, LatLng, Marker } = (window as any).google.maps;
  const bounds = new LatLngBounds();
  const markers = stores.map(
    (location, index) => {
      const position = new LatLng(location.lat, location.long);
      const maxAmountOfVisibleMarkers = 6;

      if (index < maxAmountOfVisibleMarkers) bounds.extend(position);

      const icon = highlightedMarker === location.storeNumber ? SelectedPin : Pin;

      const marker = new Marker({
        position,
        icon,
        zIndex: highlightedMarker === location.storeNumber ? 3 : 2,
        storeId: location.storeNumber
      });

      marker.addListener('click', (marker: any) => {
        onClickHandler({ lat: marker.latLng.lat(), lng: marker.latLng.lng(), storeNumber: location.storeNumber  });
      });

      return marker;
    }
  );

  return { bounds, markers };
}

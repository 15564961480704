import { useCustomerInfoQuery } from '@pizza-hut-us-development/client-core';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { RootState } from '@/rootStateTypes';
import { actions as profileActions } from '@/account/profile/profile.slice';
import { customerDetailsFailure, customerDetailsSuccess } from '@/header/actions';
import customerService from '@/services/customerService';
import { transformCustomerInfo, transformPromos } from './transformCustomerInfo';

const useCCCustomerDetails = () => {
  // Prerequisites
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  // User/App Status
  const dispatch = useDispatch();
  const isAuthenticatedUser = useSelector(userDomainSelectors.isAuthenticatedUser);
  // CC Hook
  const {
    data,
    isLoading,
    isError,
    refetch: refetchCustomerInfo,
    isUninitialized,
    isSuccess
  } = useCustomerInfoQuery(undefined, {
    skip: !isAuthenticatedUser
  });

  useEffect(() => {
    if (!data) return;
    if (!isLoading) {
      if (isError) {
        dispatch(profileActions.error());
        dispatch(customerDetailsFailure());
      }

      if (data.email) {
        const result = data && transformCustomerInfo(data);
        dispatch(profileActions.success(result));
        dispatch(
          customerDetailsSuccess({
            ...result,
            id: data.customerId,
            ...transformPromos(data)
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // see if this is double call
  useEffect(() => {
    if (!isUninitialized && isAuthenticatedUser && !isSuccess) {
      refetchCustomerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYumEcomm]);
};

export default useCCCustomerDetails;

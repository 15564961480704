import React, { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import Head from 'next/head';

/*
This module adds the usablenet sticky icon,
 which when clicked, enables accessibility mode or "usability mode" for the site
*/

const UsabilityMode = (): JSX.Element | null => {
  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'usntA40start';
    script.src = 'https://a40.usablenet.com/pt/c/pizzahut/cs-start';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Head>
      <style>{`
        #usntA40Toggle {
          bottom: 92px !important;
        }
      `}
      </style>
    </Head>
  );

  return null;
};

export default UsabilityMode;

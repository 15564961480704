import { useContext } from 'react';
import { LocalizationRailContext, LocalizationRailContextType } from '@/clientCore/localization/localizationRail/LocalizationRailProvider';

const useLocalizationRail = (): LocalizationRailContextType => {
  const context = useContext(LocalizationRailContext);
  if (!context) {
    throw new Error('useLocalizationRail is not within a LocalizationRailProvider');
  }
  return context;
};

export default useLocalizationRail;

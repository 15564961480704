const cheeseOOSBody = 'We\'re sorry, this restaurant has run out of cheese.';

const sauceOOSBody = 'We\'re sorry, this restaurant has run out of [sauce].'
  + ' '
  + 'Do you want to continue and choose another sauce?';

const toppingsOOSBody = 'We\'re sorry, this restaurant has run out of [toppings].'
  + ' '
  + 'You can continue to customize your recipe or choose another one.';

const flavorOOSBody = 'We\'re sorry, this restaurant has run out of the [flavor] crust flavor.';

const multipleOOSBody = 'We\'re sorry, this restaurant has run out of [ingredients].';

const pizzaOOSBody = 'We\'re sorry, this restaurant has run out of some ingredients in the'
  + ' [pizzaName]. You can choose another recipe or create your own';

const OneClickPizzaOOSBody = 'We\'re sorry, this restaurant has run out of [ingredients].'
  + ' You can continue adding pizza to your cart or cancel to customize your recipe.';

const oneClickProductOOSBody = 'We\'re sorry, this restaurant has run out of [ingredients].'
  + ' You can continue adding to your cart or cancel to choose another one.';

const oneClickDealProductOOSBody = 'We\'re sorry, this restaurant has run out of [ingredients].'
  + ' You can continue to customize your recipe or choose another one.';

export {
  pizzaOOSBody,
  cheeseOOSBody,
  sauceOOSBody,
  flavorOOSBody,
  toppingsOOSBody,
  multipleOOSBody,
  OneClickPizzaOOSBody,
  oneClickProductOOSBody,
  oneClickDealProductOOSBody
};

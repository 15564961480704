import { useCallback, useEffect } from 'react';
import {
  DeliveryProvider,
  DiningOccasion,
  LocalizeCustomerCartInput,
  transformCartItemToCartItemInput,
  useCreateCustomerCartMutation,
  useResetCustomerCartMutation
} from '@pizza-hut-us-development/client-core';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import { RootState } from '@/rootStateTypes';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';

import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';

import { localizationSelectors } from '@/localization/localizationSelectors';
import sessionService from '@/services/sessionService';
import { occasionLegacyToCC } from '../helpers';
import { OccasionApi } from '@/localization/constants';
import { resetToNationalStore } from '@/localization/actions';

type CreateCartInputOptions = {
  shouldResetYumCart?: boolean;
};

export const useCreateCart = () => {
  const dispatch = useDispatch();

  const isYumEcomm = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const xServerEnv: string = useSelector(
    (state: RootState) => state.coreConfig.xServerEnv
  );

  const store = useSelector(orderSelectors.store);
  const occasion = useSelector(localizationSelectors.occasion);
  const localizedAddress = useSelector(
    localizationSelectors.currentlyLocalizedAddress
  );
  const currentCart = useSelector(orderSelectors.cart);

  const [{ enabled: preventCartAddressStoreAddressFix }] = useDecision('fr-web-4054-prevent-cart-address-store-address');
  const [{ enabled: populateCartWithCoordinatesEnabled }] = useDecision('fr-web-4083-populate-cart-with-coordinates');

  useEffect(() => {
    // TODO: This could be consolidated to just use a cookie as we need it on client and server side
    localStorage.setItem('cc-x-server-env', xServerEnv);
    sessionService.saveServerEnv(xServerEnv);
  }, [xServerEnv]);

  const [createCartMutation] = useCreateCustomerCartMutation();
  const [resetCartMutation] = useResetCustomerCartMutation();

  const saveCartIdInCookie = useCallback(async (cartCCId: string) => {
    await standaloneApiClient.post(
      { cartCCId },
      StandaloneEndpoints.CREATE_CC_CART_ID_COOKIE
    );
  }, []);

  const createCart = useCallback(async (options?: CreateCartInputOptions) => {
    if (preventCartAddressStoreAddressFix && OccasionApi[occasion] === 'DELIVERY' && store?.address1 === localizedAddress?.address) {
      return;
    }

    try {
      dispatch(toggleCartLoadingStatus({ loadingState: true }));
      const existingCartItems = currentCart?.items.length
        ? transformCartItemToCartItemInput(currentCart.items)
        : [];

      if (isYumEcomm && options?.shouldResetYumCart) {
        await resetCartMutation();
      }
      const response = await createCartMutation(
        isYumEcomm
          ? ({
            storeNumber: store?.storeNumber,
            occasion: occasionLegacyToCC(occasion),
            ...(occasionLegacyToCC(occasion) !== DiningOccasion.CARRYOUT && {
              deliveryAddress: {
                address1: localizedAddress?.address ?? '',
                address2: localizedAddress?.address2 ?? '',
                city: localizedAddress?.city ?? '',
                state: localizedAddress?.state ?? '',
                postalCode: localizedAddress?.zipcode ?? '',
                countryCode: 'US',
                ...(populateCartWithCoordinatesEnabled && localizedAddress?.lat && localizedAddress?.lng && {
                  position: {
                    coordinates: [
                      localizedAddress?.lat, localizedAddress?.lng
                    ]
                  }
                })
              },
              deliveryProvider: 'INTERNAL' as DeliveryProvider
            })
          } as LocalizeCustomerCartInput)
          : existingCartItems
      );

      if ('error' in response) throw response.error;
      await saveCartIdInCookie(response.data.cartId);
    } catch (error) {
      resetToNationalStore(dispatch);
    } finally {
      dispatch(toggleCartLoadingStatus({ loadingState: false }));
    }
  }, [
    createCartMutation,
    resetCartMutation,
    saveCartIdInCookie,
    dispatch,
    isYumEcomm,
    currentCart,
    localizedAddress,
    occasion,
    store,
    preventCartAddressStoreAddressFix,
    populateCartWithCoordinatesEnabled
  ]);

  return [createCart];
};

import { Dispatch } from 'redux';
import localizationService from '../services/localizationService';
import accountService from '../services/accountService';
import {
  CARRYOUT_SEARCH_SUCCESS,
  CLOSE_LOCALIZATION_RAIL,
  CLOSE_MODAL,
  DELIVERY_SEARCH_SUCCESS,
  HIDE_LOADING_BAR,
  HIDE_LOADING_INDICATOR,
  HIDE_LOADING_QUERY,
  LOAD_SAVED_ADDRESSES,
  LOCALIZATION_INITIALIZED,
  OCCASION_CHANGED,
  OCCASION_RESET,
  OPEN_LOCALIZATION_RAIL,
  OPEN_MODAL,
  SELECT_STORE,
  SELECT_STORE_FAILED,
  SESSION_REMEMBER_OCCASION,
  SET_OCCASION_SEARCH_ADDRESS,
  SHOULD_PREFILL_INPUT,
  RESET_PREFILL_INPUT,
  SHOW_LOADING_BAR,
  SHOW_LOADING_INDICATOR,
  SHOW_LOADING_QUERY,
  SHOW_SEARCH_IN_RAIL,
  STORE_CHANGED,
  STORE_RESET,
  SWITCH_TO_CARRYOUT,
  SWITCH_TO_DELIVERY,
  USING_SAVED_ADDRESSES
} from './actionTypes';
import dataAnalytics from '@/dataAnalytics';
import getGeoLocation from './common/geoLocation/getGeoLocation';
import { carryoutToString } from './common/utils';
import router from '../router';
import {
  onOpenLocalizationRail,
  SearchFormAnalyticsActionType,
  storeSearchFormAnalytics,
  storeSearchNoResultsActionAnalytics,
  storeSearchNoResultsAnalytics,
  storeSearchSuccessAnalytics
} from '@/dataAnalytics/dataAnalyticsHelper';
import connectionConfig from '../services/connectionConfig';
import { NATIONAL, Occasion, OccasionString } from './constants';
import {
  clearStoreCart,
  removeAlcoholFromCart
} from '@/cart/legacyCart/legacyActions';
import { StoreSearchException } from '@/services/localizationService/phdapiLocalizationService';
import { updateUrl } from '@/common/url';
import { AsyncDispatch } from './localizeActions';
import logger from '../common/logger';
import telemetry from '../telemetry';
import { getLocalizationTokenDetail } from './localizationToken';
import {
  ReorderStatus,
  actions as reorderActions
} from '@/account/orders/reorder.slice';
import { showErrorModal } from '@/common/Modal';
import { BaseAnalytics } from '@/dataAnalytics/analyticsTypes';
import { RootState } from '@/rootStateTypes';
import { setStoreNumber } from '@/../optimizely/utils/attributeHelpers';
import { setlocalizedCustomerCookieAttribute } from '../../optimizely/utils/create-localized-customer-details-cookie';
import {
  Cart,
  CCStoreResponse,
  DeliveryAddress as CCDeliveryAddress,
  DiningOccasion,
  LocalizeCustomerCartInput,
  OrderActions,
  orderApiCart,
  storeApiInfo
} from '@pizza-hut-us-development/client-core';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';
import { transformCCStoreToStoreDetail } from '@/clientCore/localization/helpers';
import isOptimizelyFeatureEnabled from '../../optimizely/utils/isOptimizelyFeatureEnabled';

const GMT_TIMEZONE = 'Europe/London';

export const carryoutSearchSuccess = (
  options: CarryoutSearchDetails,
  carryoutSearchResults: any
) => ({
  type: CARRYOUT_SEARCH_SUCCESS,
  zipcode: options.zipcode,
  lat: options.lat,
  lng: options.lng,
  city: options.city,
  state: options.state,
  carryoutSearchResults
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const openModal = (data: ModalContent) => ({
  type: OPEN_MODAL,
  data
});

export const deliverySearchSuccess = (
  address: string,
  address2: string,
  city: string,
  state: string,
  zipcode: string,
  deliverySearchResults: any
) => ({
  type: DELIVERY_SEARCH_SUCCESS,
  address,
  address2,
  city,
  state,
  zipcode,
  deliverySearchResults
});

export const setCurrentlyLocalizedAddress = ({
  address,
  address2,
  city,
  state,
  zipcode,
  lat,
  lng
}: DeliveryAddress) => ({
  type: SET_OCCASION_SEARCH_ADDRESS,
  address,
  address2,
  city,
  state,
  zipcode,
  lat,
  lng
});

export const loadSavedAddresses = (addresses: SavedAddress[]) => ({
  type: LOAD_SAVED_ADDRESSES,
  addresses
});

export const usingSavedAddresses = (usingSavedAddresses: boolean) => ({
  type: USING_SAVED_ADDRESSES,
  usingSavedAddresses
});

export const openLocalizationRail =
  (
    options?:
      | {
          routeTo: string;
          handleAfterLocalizing?: (localizationToken?: string) => void;
        }
      | {
          routeTo?: string;
          handleAfterLocalizing: (localizationToken?: string) => void;
        }
  ): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    let savedAddresses = [];
    const presentationalLocalization = getState().presentational.localization;
    /**
     * standalone gets saved customer addresses from /customer endpoint
     * embedded gets saved customer addresses from account service
     */
    if (process.env.REACT_APP_EMBEDDED === 'true') {
      savedAddresses = await accountService.getUserSavedAddresses();
      dispatch(loadSavedAddresses(savedAddresses));
    } else {
      savedAddresses = presentationalLocalization.savedAddresses;
    }
    dispatch({
      type: OPEN_LOCALIZATION_RAIL,
      routeTo: options?.routeTo,
      handleAfterLocalizing: options?.handleAfterLocalizing
    });

    const { analyticsDataModel } = getState();
    const savedAddressesAreVisible =
      presentationalLocalization.useSavedAddresses &&
      savedAddresses?.length > 0;
    pushEventToAnalytics(
      () => onOpenLocalizationRail(savedAddressesAreVisible),
      analyticsDataModel
    );

    telemetry.addCustomEvent('web2-localization-rail-open');
  };

export const closeLocalizationRail = () => ({
  type: CLOSE_LOCALIZATION_RAIL
});

export const switchToDelivery = () => ({
  type: SWITCH_TO_DELIVERY
});

export const switchToCarryout = () => ({
  type: SWITCH_TO_CARRYOUT
});

export const shouldPrefillInput = () => ({
  type: SHOULD_PREFILL_INPUT
});

export const resetPrefillInput = () => ({
  type: RESET_PREFILL_INPUT
});

const showLoadingIndicator = () => ({
  type: SHOW_LOADING_INDICATOR
});

const hideLoadingIndicator = () => ({
  type: HIDE_LOADING_INDICATOR
});

export const showSearchInRail = () => ({
  type: SHOW_SEARCH_IN_RAIL
});

export const rememberOccasion = (sessionRememberOccasion: Occasion) => ({
  type: SESSION_REMEMBER_OCCASION,
  sessionRememberOccasion
});

export const selectStore = (
  storeNumber: string,
  occasion?: Occasion,
  store?: StoreDetail,
  storeTimezone?: string
) => ({
  type: SELECT_STORE,
  storeNumber,
  store,
  occasion,
  storeTimezone
});

export const localizationInitialized = () => ({
  type: LOCALIZATION_INITIALIZED
});

export function tokenHasExpired(expirationDate?: number, source?: string) {
  if (!expirationDate) {
    return true;
  }
  const expiredDate = new Date(expirationDate) < new Date();

  if (expiredDate) {
    telemetry.addCustomEvent(`Localization token has expired`, {
      expiredDate,
      source
    });
  }

  return expiredDate;
}

export function tokenIssuedLongAgo(
  issuedDate?: number,
  baseLocalizationIAT = '2022-07-21',
  source?: string
) {
  if (!issuedDate) {
    return false;
  }
  const issuedLongAgo = new Date(issuedDate) < new Date(baseLocalizationIAT);

  if (issuedLongAgo) {
    telemetry.addCustomEvent(
      `Localization token has an issued date before ${baseLocalizationIAT}`,
      { issuedDate, source }
    );
  }

  return issuedLongAgo;
}

export const initializeLocalization =
  (
    occasion = Occasion.UNKNOWN,
    storeNumber: string,
    expirationDate?: number,
    issuedDate?: number,
    baseLocalizationIAT?: any,
    localizationToken?: string,
    localizationTokenSource?: string
  ): AsyncDispatch =>
  async (dispatch: Dispatch) => {
    const apiOccasion = OccasionString[occasion]?.toUpperCase();

    const saveStoreDetails = ({ token }: RefreshedTokenResponse) => {
      const getStoreDetails = localizationService.getStoreDetails(
        storeNumber,
        apiOccasion,
        token,
        false
      );

      getStoreDetails.then((storeDetails: any) => {
        dispatch(selectStore(storeNumber, occasion, storeDetails.result));
        dispatch(localizationInitialized());
        setStoreNumber(storeNumber);
        setlocalizedCustomerCookieAttribute({ storeNumber, occasion });
      });
    };

    const localizeToNational = () => {
      dispatch(selectStore(NATIONAL));
      dispatch(localizationInitialized());
      telemetry.addNoticeError(
        new Error(
          `Was not able to retrieve and save store details for store ${storeNumber}`
        )
      );
    };

    if (
      localizationTokenSource === 'state' ||
      tokenIssuedLongAgo(
        issuedDate,
        baseLocalizationIAT,
        localizationTokenSource
      ) ||
      tokenHasExpired(expirationDate, localizationTokenSource) ||
      localizationTokenSource === 'network'
    ) {
      await localizationService
        .refreshLocalizationCookie(localizationToken)
        .then(saveStoreDetails)
        .catch(localizeToNational);
    } else {
      saveStoreDetails({ token: localizationToken ?? '' });
      await localizationService.updateLocalizationCookie().catch((error) => {
        telemetry.addNoticeError(error);
        logger.withoutTelemetry.error(error);
      });
    }
  };

const isDifferentStore = (storeNumber: string, previousStoreNumber: string) =>
  storeNumber !== previousStoreNumber;

const showConnectivityIssueModal = (
  occasion: string,
  isSavedAddress?: boolean
) => {
  const title = "We're sorry!";
  const body =
    "We're currently experiencing connectivity issues. Please try entering your location again.";
  const text = 'OK';

  return openModal({
    title,
    body,
    cta: {
      text
    },
    analyticsModalData: {
      occasion,
      isSavedAddress,
      storeSearchStage: 'Form'
    }
  });
};

const showSearchStoresErrorModal = (
  occasion: string,
  isSavedAddress?: boolean
) => {
  const title = "We're sorry!";
  const body = 'We had trouble loading that page. Please try again.';
  const text = 'Try Again';
  return openModal({
    title,
    body,
    cta: {
      text,
      callback: () => {
        updateUrl('/link.php?localize');
      }
    },
    analyticsModalData: {
      occasion,
      isSavedAddress,
      storeSearchStage: 'Form'
    }
  });
};

const handleSearchStoresError = (
  dispatch: Dispatch,
  occasion: string,
  isSavedAddress?: boolean
) => {
  dispatch(showSearchStoresErrorModal(occasion, isSavedAddress));
};

export const handleGeoLocationError = (dispatch: Dispatch) => {
  const title = 'Geolocation error';
  const body =
    'We are currently blocked from using your location, you must grant us permission.';

  dispatch(hideLoadingIndicator());
  dispatch(
    openModal({
      title,
      body,
      cta: {
        text: 'OK'
      },
      analyticsModalData: {
        occasion: 'Carryout',
        isSavedAddress: false,
        storeSearchStage: 'Form'
      }
    })
  );
};

const handleCarryoutNoStoresFound = (
  dispatch: Dispatch,
  latlong: CarryoutSearchDetails,
  analyticsDataModel: any,
  errorCode?: string,
  options?: CarryoutSearchDetails,
  isSavedAddress?: boolean
) => {
  const locationString = options ? `: ${carryoutToString(options)}` : ' you';
  const title = "We're sorry!";
  const body = `There are no available Pizza Huts near${locationString}.`;
  const text = 'ENTER NEW LOCATION';

  pushEventToAnalytics(
    () =>
      storeSearchNoResultsAnalytics(
        'Carryout',
        options || latlong,
        isSavedAddress
      ),
    analyticsDataModel
  );

  dispatch(hideLoadingIndicator());
  dispatch(
    openModal({
      title,
      body,
      cta: {
        text,
        callback: () => {
          pushEventToAnalytics(
            () =>
              storeSearchNoResultsActionAnalytics(
                'Carryout',
                'Enter New Address',
                isSavedAddress
              ),
            analyticsDataModel
          );
        }
      },
      analyticsModalData: {
        occasion: 'Carryout',
        storeSearchStage: 'Form',
        errorCode,
        isSavedAddress
      }
    })
  );
};

export const handleDeliveryNoStoresFound = (
  dispatch: Dispatch,
  deliveryAddress: DeliveryAddress,
  analyticsDataModel: any,
  isSavedAddress?: boolean,
  errorCode?: string
) => {
  dispatch(hideLoadingIndicator());

  const title = "We're sorry!";
  const body =
    'There are no available Pizza Huts that can deliver to: ' +
    `${deliveryAddress.address}, ${deliveryAddress.city}, ${deliveryAddress.state} ${deliveryAddress.zipcode}.`;
  pushEventToAnalytics(
    () =>
      storeSearchNoResultsAnalytics(
        'Delivery',
        {
          city: deliveryAddress.city,
          state: deliveryAddress.state,
          zipcode: deliveryAddress.zipcode
        },
        isSavedAddress
      ),
    analyticsDataModel
  );

  dispatch(
    openModal({
      title,
      body,
      cta: {
        text: 'ENTER NEW ADDRESS',
        callback: () => {
          dispatch(showSearchInRail());
          pushEventToAnalytics(
            () =>
              storeSearchNoResultsActionAnalytics(
                'Delivery',
                'Enter New Address',
                isSavedAddress
              ),
            analyticsDataModel
          );
        }
      },
      altCta: {
        text: 'SWITCH TO CARRYOUT',
        callback: () => {
          dispatch(showSearchInRail());
          dispatch(switchToCarryout());
          pushEventToAnalytics(
            () =>
              storeSearchNoResultsActionAnalytics(
                'Delivery',
                'Switch To Carryout',
                isSavedAddress
              ),
            analyticsDataModel
          );
        }
      },
      analyticsModalData: {
        occasion: 'Delivery',
        isSavedAddress,
        errorCode,
        storeSearchStage: 'Form'
      }
    })
  );
};

const isUserLocalizingForFirstTime = (previousStoreNumber: string): boolean =>
  previousStoreNumber !== NATIONAL;

/**
 * @function handleStoreSearchExceptionExperience
 * @description handles rendering an inline error message or an error modal for when searching for stores with an invalid location
*/
const handleStoreSearchExceptionExperience = (
  {decision, decisionEnabledExperience, decisionDisabledExperience}: HandleStoreSearchExceptionExperience
) => {
  if(decision) {
    decisionEnabledExperience();
  } else {
    const [handleSearchStoresError, dispatch, occasion, isSavedAddress] = decisionDisabledExperience;
    handleSearchStoresError(dispatch, occasion, isSavedAddress);
  }
}

export function searchCarryout(
  {location, invalidLocationCallback, isSavedAddress, isCarryoutLocalizationErrorDecision}: CarryoutSearchByLocation
): AsyncDispatch {
  return async (dispatch, getState = () => {}) => {
    dispatch(showLoadingIndicator());

    const { analyticsDataModel } = getState();

    try {
      const { found, stores, errorCode } =
        await localizationService.findCarryoutStores(location);

      if (!found) {
        handleCarryoutNoStoresFound(
          dispatch,
          {},
          analyticsDataModel,
          errorCode,
          location,
          isSavedAddress
        );
        return;
      }

      dispatch(carryoutSearchSuccess(location, stores));
      dispatch(hideLoadingIndicator());
      pushEventToAnalytics(
        () => storeSearchSuccessAnalytics('Carryout', isSavedAddress),
        analyticsDataModel
      );
      telemetry.addCustomEvent('web2-search-carryout-stores');
    } catch (ex) {
      dispatch(hideLoadingIndicator());
      const error = ex as Error;
      if (error.message === connectionConfig.requestTimeoutErrorMessage) {
        dispatch(showConnectivityIssueModal('Carryout', isSavedAddress));
      } else if (error instanceof StoreSearchException) {

        handleStoreSearchExceptionExperience({
          decision: isCarryoutLocalizationErrorDecision!,
          decisionEnabledExperience: invalidLocationCallback!,
          decisionDisabledExperience: [handleSearchStoresError, dispatch, 'Carryout', isSavedAddress]
        });
      } else {
        showErrorModal(dispatch);
      }
      telemetry.addNoticeError(error);
    }
  };
}

export function searchCarryoutV2(
  {location, invalidLocationCallback, isSavedAddress, isCarryoutLocalizationErrorDecision}: CarryoutSearchByLocation
): AsyncDispatch {
  return async (dispatch, getState = () => {}) => {
    dispatch(showLoadingIndicator());

    const { analyticsDataModel } = getState();

    // Search for a specific store number
    let searchedStoreNumber = '';
    if (location.city?.includes('store-')) {
      searchedStoreNumber = location.city.substring(location.city.indexOf('-') + 1);
      const { result } = await localizationService.getStoreDetails(searchedStoreNumber, 'C');
      if (result) location = { city: result.city, state: result.state, zipcode: result.zipcode };
    }
    try {
      const { found, stores, errorCode } =
        await localizationService.findCarryoutStoresV2(location);

      if (!found) {
        handleCarryoutNoStoresFound(
          dispatch,
          {},
          analyticsDataModel,
          errorCode,
          location,
          isSavedAddress
        );
        return;
      }
      const filteredStores = searchedStoreNumber.length ? stores?.filter((store) => store.storeNumber === searchedStoreNumber) : stores;
      dispatch(carryoutSearchSuccess(location, filteredStores));
      dispatch(hideLoadingIndicator());
      pushEventToAnalytics(
        () => storeSearchSuccessAnalytics('Carryout', isSavedAddress),
        analyticsDataModel
      );
      telemetry.addCustomEvent('web2-search-carryout-stores');
    } catch (ex) {
      dispatch(hideLoadingIndicator());
      const error = ex as Error;
      if (error.message === connectionConfig.requestTimeoutErrorMessage) {
        dispatch(showConnectivityIssueModal('Carryout', isSavedAddress));
      } else if (error instanceof StoreSearchException) {

        handleStoreSearchExceptionExperience({
          decision: isCarryoutLocalizationErrorDecision!,
          decisionEnabledExperience: invalidLocationCallback!,
          decisionDisabledExperience: [handleSearchStoresError, dispatch, 'Carryout', isSavedAddress]
        });
      } else {
        showErrorModal(dispatch);
      }
      telemetry.addNoticeError(error);
    }
  };
};

export const searchCarryoutByLatLongV2 =
  ({invalidLocationCallback, isCarryoutLocalizationErrorDecision}: CarryoutSearch): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const { analyticsDataModel } = getState();
    dispatch(showLoadingIndicator());
    pushEventToAnalytics(
      () =>
        storeSearchFormAnalytics(
          'Carryout',
          SearchFormAnalyticsActionType.USE_GPS
        ),
      analyticsDataModel
    );

    let latlong;
    try {
      latlong = await getGeoLocation();
    } catch (e) {
      handleGeoLocationError(dispatch);
      telemetry.addNoticeError(e as Error);
      return;
    }

    try {
      const { found, stores, errorCode } =
        await localizationService.findCarryoutStoresByLatLongV2(latlong);

      if (stores?.length === 0 || !found) {
        handleCarryoutNoStoresFound( 
          dispatch,
          latlong,
          analyticsDataModel,
          errorCode,
          undefined,
          false
        );
        return;
      }

      dispatch(carryoutSearchSuccess(latlong, stores));
      dispatch(hideLoadingIndicator());
      pushEventToAnalytics(
        () => storeSearchSuccessAnalytics('Carryout'),
        analyticsDataModel
      );
      telemetry.addCustomEvent('web2-search-carryout-stores');
    } catch (e) {
      dispatch(hideLoadingIndicator());
      const error = e as Error;
      if (error.message === connectionConfig.requestTimeoutErrorMessage) { 
        dispatch(showConnectivityIssueModal('Carryout', false));
      } else if (error instanceof StoreSearchException) {

        handleStoreSearchExceptionExperience({
          decision: isCarryoutLocalizationErrorDecision!,
          decisionEnabledExperience: invalidLocationCallback!,
          decisionDisabledExperience: [handleSearchStoresError, dispatch, 'Carryout', false]
        });
      } else {
        showErrorModal(dispatch);
      }

      telemetry.addNoticeError(error);
    }
  };

const deliverySearch = async (
  dispatch: Dispatch,
  deliveryAddress: DeliveryAddress,
  findDeliveryStores: () => Promise<StoreSearchResult>,
  analyticsDataModel: any,
  isSavedAddress?: boolean
) => {
  dispatch(showLoadingIndicator());
  try {
    const { found, stores, deliveryAddresses, errorCode } =
      await findDeliveryStores();

    if (!found && !deliveryAddresses) {
      handleDeliveryNoStoresFound(
        dispatch,
        deliveryAddress,
        analyticsDataModel,
        isSavedAddress,
        errorCode
      );
      return;
    }

    dispatch(
      deliverySearchSuccess(
        deliveryAddress.address,
        deliveryAddress.address2 ?? '',
        deliveryAddress.city,
        deliveryAddress.state,
        deliveryAddress.zipcode,
        { stores, deliveryAddresses }
      )
    );
    dispatch(hideLoadingIndicator());
    pushEventToAnalytics(
      () => storeSearchSuccessAnalytics('Delivery', isSavedAddress),
      analyticsDataModel
    );
    telemetry.addCustomEvent('web2-search-delivery-stores');
  } catch (ex) {
    dispatch(hideLoadingIndicator());
    const error = ex as Error;
    if (error.message === connectionConfig.requestTimeoutErrorMessage) {
      dispatch(showConnectivityIssueModal('Delivery', isSavedAddress));
    } else if (error instanceof StoreSearchException) {
      handleSearchStoresError(dispatch, 'Delivery', isSavedAddress);
    } else {
      showErrorModal(dispatch);
    }

    telemetry.addNoticeError(error);
  }
};

export const searchDelivery =
  (
    options: {
      address: string;
      address2?: string;
      city: string;
      state: string;
      zipcode: string;
    },
    isSavedAddress?: boolean
  ): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const { domain, analyticsDataModel } = getState();
    const previousStoreNumber = domain.localization.localizedStore;
    const { hasAlcoholInCart } = domain.cartLegacy;

    // Search for a specific store number
    if (options.address.includes('store-')) {
      const storeNumber = options.address.substring(options.address.indexOf('-') + 1);
      const { result } = await localizationService.getStoreDetails(storeNumber, 'D');
      if (result) options = { address: result.address, city: result.city, state: result.state, zipcode: result.zipcode };
    }

    await deliverySearch(
      dispatch,
      options,
      () =>
        localizationService.findDeliveryStores(
          options,
          previousStoreNumber,
          hasAlcoholInCart
        ),
      analyticsDataModel,
      isSavedAddress
    );
  };

export const searchDeliveryByLatLng =
  (deliveryAddress: DeliveryAddress, skipGeoCode?: boolean): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const { analyticsDataModel } = getState();

    await deliverySearch(
      dispatch,
      deliveryAddress,
      () =>
        localizationService.findDeliveryStoresByLatLong(
          deliveryAddress,
          skipGeoCode
        ),
      analyticsDataModel,
      false
    );
  };

  export const searchDeliveryV2 =
  (
    options: {
      address: string;
      address2?: string;
      city: string;
      state: string;
      zipcode: string;
    },
    isSavedAddress?: boolean
  ): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const { domain, analyticsDataModel } = getState();
    const previousStoreNumber = domain.localization.localizedStore;
    const { hasAlcoholInCart } = domain.cartLegacy;

    // Search for a specific store number
    if (options.address.includes('store-')) {
      const storeNumber = options.address.substring(options.address.indexOf('-') + 1);
      const { result } = await localizationService.getStoreDetails(storeNumber, 'D');
      if (result) options = { address: result.address, city: result.city, state: result.state, zipcode: result.zipcode };
    }

    await deliverySearch(
      dispatch,
      options,
      () =>
        localizationService.findDeliveryStoresV2(
          options,
          previousStoreNumber,
          hasAlcoholInCart
        ),
      analyticsDataModel,
      isSavedAddress
    );
  };

export const searchDeliveryByLatLngV2 =
  (deliveryAddress: DeliveryAddress, skipGeoCode?: boolean): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const { analyticsDataModel } = getState();

    await deliverySearch(
      dispatch,
      deliveryAddress,
      () =>
        localizationService.findDeliveryStoresByLatLongV2(
          deliveryAddress,
          skipGeoCode
        ),
      analyticsDataModel,
      false
    );
  };

const failToSelectStore = (storeNumber: string, occasion: Occasion) => ({
  type: SELECT_STORE_FAILED,
  storeNumber,
  occasion
});

export const deleteCCCartCookie = async () =>
  standaloneApiClient.post(
    { ccCartId: '' },
    StandaloneEndpoints.CREATE_CC_CART_ID_COOKIE
  );

export const resetCCCartState = async (dispatch: Dispatch, isSuccessfulOrder = false) => {
  const { setCart, setCartId } = OrderActions;
  dispatch(setCart(undefined as unknown as Cart));
  dispatch(setCartId(''))
};

export const selectCarryoutStore =
  (options: {
    storeNumber: string;
    storeToken: string;
    address?: DeliveryAddress;
    onSuccess?: (localizationToken?: string) => void;
  }): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const {
      storeNumber,
      storeToken,
      address,
      onSuccess = (localizationToken?: string) => {}
    } = options;
    const tokenDetail = getLocalizationTokenDetail(storeToken);
    const storeTimezone = tokenDetail ? tokenDetail.timezone : GMT_TIMEZONE;

    if (shouldClearCart(getState().domain, storeNumber)) {
      dispatch(clearStoreCart(selectNewStore));
    } else {
      await selectNewStore();
    }

    async function selectNewStore() {
      const state = getState();
      const previousStoreNumber = state.domain.localization.localizedStore;
      const previousStoreOccasion = state.domain.localization.localizedOccasion;
      const reorderState = state.domain.reorder.state;

      const { result: selectedStore, error } =
        await localizationService.selectCarryoutStore(options);
      if (!error) {
        onSuccess(selectedStore?.storeToken);
      
        await deleteCCCartCookie();
        if (previousStoreNumber !== selectedStore?.storeNumber) {
          // Clear CC Store/Cart
          await resetCCCartState(dispatch);
        }

        telemetry.addCustomAttribute('storeNumber', storeNumber);
        const { routeTo, handleAfterLocalizing } =
          state.presentational.localization.rail.options;

        dispatch(
          setCurrentlyLocalizedAddress(address ?? ({} as DeliveryAddress))
        );

        dispatch(
          selectStore(
            storeNumber,
            Occasion.CARRYOUT,
            selectedStore,
            storeTimezone
          )
        );
        setStoreNumber(storeNumber);
        let occasion = Occasion.CARRYOUT;
        setlocalizedCustomerCookieAttribute({ storeNumber, occasion });

        if (
          isLocalizingToADifferentStore(previousStoreNumber, storeNumber) ||
          previousStoreOccasion === 'D'
        ) {
          if (handleAfterLocalizing) {
            handleAfterLocalizing(storeToken);
          }
          if (routeTo) {
            router.goToRoute(routeTo);
          }
        }

        dispatch(closeLocalizationRail());

        telemetry.addCustomEvent('web2-select-carryout-store');
      } else {
        dispatch(
          setCurrentlyLocalizedAddress(address ?? ({} as DeliveryAddress))
        );
        dispatch(failToSelectStore(storeNumber, Occasion.CARRYOUT));
        dispatch(closeLocalizationRail());
        telemetry.addNoticeError(
          new Error(`Failure to select carryout store ${storeNumber}`)
        );
      }

      if (reorderState !== ReorderStatus.READY) {
        dispatch(reorderActions.doneLocalizing());
      }
    }
  };

export const selectDeliveryStore =
  (options: {
    storeNumber: string;
    deliveryAddress: DeliveryAddress;
    removeAlcohol: boolean;
    storeToken: string;
    onSuccess?: (localizationToken: string) => void;
  }): AsyncDispatch =>
  async (dispatch, getState = () => {}) => {
    const {
      storeNumber,
      deliveryAddress,
      storeToken,
      removeAlcohol,
      onSuccess = () => {}
    } = options;
    const tokenDetail = getLocalizationTokenDetail(storeToken);
    const storeTimezone = tokenDetail ? tokenDetail.timezone : GMT_TIMEZONE;

    if (
      shouldRemoveAlcoholCart(getState().domain, storeNumber, removeAlcohol)
    ) {
      dispatch(removeAlcoholFromCart(selectNewStore));
    } else if (shouldClearCart(getState().domain, storeNumber)) {
      dispatch(clearStoreCart(selectNewStore));
    } else {
      await selectNewStore();
    }

    async function selectNewStore() {
      const state = getState();
      const previousStoreNumber = state.domain.localization.localizedStore;
      const previousStoreOccasion = state.domain.localization.localizedOccasion;
      const reorderState = state.domain.reorder.state;

      const { result: selectedStore, error } =
        await localizationService.selectDeliveryStore(
          storeNumber,
          deliveryAddress,
          storeToken
        );
      if (!error) {
        onSuccess(storeToken);

        await deleteCCCartCookie();
        if (previousStoreNumber !== selectedStore?.storeNumber) {
          // Clear CC Store/Cart
          await resetCCCartState(dispatch);
        }

        telemetry.addCustomAttribute('storeNumber', storeNumber);
        const { routeTo, handleAfterLocalizing } =
          getState().presentational.localization.rail.options;

        dispatch(
          selectStore(
            storeNumber,
            Occasion.DELIVERY,
            selectedStore,
            storeTimezone
          )
        );
        dispatch(setCurrentlyLocalizedAddress(options.deliveryAddress));
        setStoreNumber(storeNumber);
        let occasion = Occasion.DELIVERY;
        setlocalizedCustomerCookieAttribute({ storeNumber, occasion });

        if (
          isLocalizingToADifferentStore(previousStoreNumber, storeNumber) ||
          previousStoreOccasion === 'C'
        ) {
          if (handleAfterLocalizing) {
            handleAfterLocalizing(storeToken);
          }
          if (routeTo) {
            router.goToRoute(routeTo);
          }
        }

        dispatch(closeLocalizationRail());
        telemetry.addCustomEvent('web2-select-delivery-store');
      } else {
        dispatch(setCurrentlyLocalizedAddress(options.deliveryAddress));
        dispatch(failToSelectStore(options.storeNumber, Occasion.DELIVERY));
        dispatch(closeLocalizationRail());
        telemetry.addNoticeError(
          new Error(`Failure to select delivery store ${options.storeNumber}`)
        );
      }

      if (reorderState !== ReorderStatus.READY) {
        dispatch(reorderActions.doneLocalizing());
      }
    }
  };

function pushEventToAnalytics(
  eventBuilder: () => Partial<BaseAnalytics>,
  analyticsDataModel: AnalyticsModalData
) {
  try {
    const event = eventBuilder();
    dataAnalytics.push(event, analyticsDataModel);
  } catch (e) {
    logger.error(e as Error);
  }
}

function shouldShowContactlessModal(
  previousStoreNumber: string,
  selectedStore: StoreDetail
) {
  return (
    isUserLocalizingForFirstTime(previousStoreNumber) &&
    selectedStore?.contactless &&
    selectedStore?.contactless.showContactlessModal
  );
}

function isLocalizingToADifferentStore(
  previousStoreNumber: string,
  storeNumber: string
) {
  return isDifferentStore(storeNumber, previousStoreNumber);
}

function shouldRemoveAlcoholCart(
  domain: RootState,
  storeNumber: string,
  removeAlcohol: boolean
) {
  const previousStoreNumber = domain.localization.localizedStore;
  return (
    removeAlcohol &&
    !isLocalizingToADifferentStore(previousStoreNumber, storeNumber)
  );
}

function shouldClearCart(domain: RootState, storeNumber: string) {
  const cartQuantity = domain.cartLegacy.quantity;
  const previousStoreNumber = domain.localization.localizedStore;
  return (
    cartQuantity > 0 &&
    isLocalizingToADifferentStore(previousStoreNumber, storeNumber)
  );
}

export const selectors = {
  selectModalDetails: (state: RootState) =>
    state.presentational.localization.modal
};

export const showLoadingBar = () => ({
  type: SHOW_LOADING_BAR
});

export const hideLoadingBar = () => ({
  type: HIDE_LOADING_BAR
});

export const storeChanged = () => ({
  type: STORE_CHANGED
});
export const storeReset = () => ({
  type: STORE_RESET
});

export const occasionChanged = () => ({
  type: OCCASION_CHANGED
});

export const occasionReset = () => ({
  type: OCCASION_RESET
});

export const showLoadingQuery = () => ({
  type: SHOW_LOADING_QUERY
});

export const hideLoadingQuery = () => ({
  type: HIDE_LOADING_QUERY
});


const saveCartIdInCookie = async (cartCCId: string) => {
  await standaloneApiClient.post(
    { cartCCId },
    StandaloneEndpoints.CREATE_CC_CART_ID_COOKIE
  );
};

const saveLocalizationCookie = async (payload: LocalizeCustomerCartInput) => {
  await standaloneApiClient.post(
    { payload },
    StandaloneEndpoints.CREATE_CC_LOCALIZATION_COOKIE
  );
};

// Used in Optimizely experiments to determine store and occasion the customer
// is currently localized with.
const setOptimizelyLocalizationCookies = (storeNumber: string, diningOccasion: string) => {
  const occasion = diningOccasion === DiningOccasion.CARRYOUT ? 'C' : 'D';
  setStoreNumber(storeNumber);
  setlocalizedCustomerCookieAttribute({ storeNumber, occasion });
}

export const setLegacyLocalizationState = async (dispatch: Dispatch, store: CCStoreResponse, occasion: DiningOccasion, deliveryAddress?: CCDeliveryAddress) => {
  const transformedOccasion = occasion === DiningOccasion.CARRYOUT ? Occasion.CARRYOUT : Occasion.DELIVERY;
  const populateCartWithCoordinatesEnabled = isOptimizelyFeatureEnabled('fr-web-4083-populate-cart-with-coordinates');
  dispatch(selectStore(
    store.storeNumber,
    transformedOccasion,
    transformCCStoreToStoreDetail(store, occasion),
    store.timezone
  ));

  setOptimizelyLocalizationCookies(store.storeNumber, occasion); 

  if (store.token) {
    await standaloneApiClient.post(
      { token: store.token },
      StandaloneEndpoints.SAVE_LOCALIZATION_TOKEN
    );
  }

  if (deliveryAddress) {
    if(populateCartWithCoordinatesEnabled) {
      dispatch(setCurrentlyLocalizedAddress({
        address: deliveryAddress.address1 ?? '',
        address2: deliveryAddress.address2 ?? '',
        city: deliveryAddress.city ?? '',
        state: deliveryAddress.state ?? '',
        zipcode: deliveryAddress.postalCode ?? '',
        lat: deliveryAddress?.position?.coordinates?.[0],
        lng: deliveryAddress?.position?.coordinates?.[1]
      }));
    } else {
      dispatch(setCurrentlyLocalizedAddress({
        address: deliveryAddress.address1 ?? '',
        address2: deliveryAddress.address2 ?? '',
        city: deliveryAddress.city ?? '',
        state: deliveryAddress.state ?? '',
        zipcode: deliveryAddress.postalCode ?? '',
      }));
    }
  }
};

export const resetToNationalStore = (dispatch: Dispatch) => {
  dispatch(selectStore(NATIONAL));
  dispatch(localizationInitialized());
}

export const initializeCCLocalization =
  (
    payload: LocalizeCustomerCartInput,
    storeToken?: string,
    cartId?: string | null,
  ): AsyncDispatch =>
  async (dispatch: Dispatch) => {
    const storeInfo = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(payload.storeNumber));
    const storeDetails = {...storeInfo.data, token: storeToken };
    
    dispatch(OrderActions.setStore(storeDetails));

    setLegacyLocalizationState(dispatch, storeDetails, payload.occasion, payload.deliveryAddress)

    if (cartId) {
      dispatch(OrderActions.setCartId(cartId));
      return;
    }
    
    // Cart cookie expired
    try {
      const cartResponse = await dispatch(orderApiCart.endpoints.createCustomerCart.initiate(payload));
      if (!cartResponse.data) { 
        throw new Error('Failed to create cart');
      }
      await Promise.all([
        saveCartIdInCookie(cartResponse.data.cartId),
        saveLocalizationCookie(payload)
      ]);
    } catch {
      resetToNationalStore(dispatch);
    }
  };


import React, {
  useState, useEffect, useRef, useMemo
} from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { DiningOccasion } from '@pizza-hut-us-development/client-core';

import StoreTile from './StoreTile';
import {
  selectCarryoutStore,
  switchToCarryout,
  switchToDelivery
} from '../actions';
import { LOCALIZATION_SERVICE_X_VARIANT, Occasion } from '../constants';
import LinkButton from '../../common/LinkButton';
import Map from '../common/google/Map';
import {
  onSearchCarryoutAnalytics,
  storeResultsAnalytics,
  storeResultsViewButtonAnalytics
} from '../../dataAnalytics/dataAnalyticsHelper';
import styles from './CarryoutResults.styles';
import useLocationConfirmationModal from '../../common/useLocationConfirmationModal';

import { RootState } from '@/rootStateTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import useLocalizationRail from '@/clientCore/localization/localizationRail/useLocalizationRail';
import useLocalization from '@/clientCore/localization/useLocalization';
import { transformCCStoreToStoreDetail } from '@/clientCore/localization/helpers';

interface CarryoutResultsProps {
  zipcode: string;
  city: string;
  state: string;
  storesByDistance: Array<StoreDetail>;
  selectCarryoutStore: (
    options: {
      storeNumber: string;
      storeToken: string;
      address: DeliveryAddress;
      onSuccess?: (localizationToken?: string) => void;
    }) => void;
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  onEdit?: () => void;
}

function CarryoutResults({
  zipcode,
  city,
  state,
  onEdit,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  switchToCarryout, switchToDelivery
}: CarryoutResultsProps) {
  const { results } = useLocalizationRail();
  const { localizeCarryout } = useLocalization();

  const transformedStores = useMemo(() => results?.map((store) => transformCCStoreToStoreDetail(store, DiningOccasion.CARRYOUT)) ?? [], [results]);
  const storesByDistance = transformedStores;

  const classes = styles();
  const [expanded, setExpanded] = useState('store-tile-0');
  const [storesBySelection, setStoresBySelection] = useState(transformedStores);

  const [selectedStoreNumber, setSelectedStoreNumber] = useState<string>('');
  const storesNearRef = useRef<HTMLDivElement>(null);
  const analytics = useAnalytics();
  // const localizationToken = useSelector(localizationSelectors.localizationToken);
  // Prevent modal from popping when localizing during an active browser session
  const { suppressConfirmationModal } = useLocationConfirmationModal();

  useEffect(() => {
    analytics.push(() => storeResultsAnalytics('Carryout', { city, state, zipcode }, storesByDistance.length));

    const localizationV2Version = localStorage.getItem(LOCALIZATION_SERVICE_X_VARIANT);
    if (localizationV2Version) {
      analytics.push(() => onSearchCarryoutAnalytics(localizationV2Version));
    }
    localStorage?.removeItem(LOCALIZATION_SERVICE_X_VARIANT);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, state, zipcode]);

  useEffect(() => {
    const isStoresBySelectionEmpty = !storesBySelection || storesBySelection.length === 0;
    if (isStoresBySelectionEmpty) setStoresBySelection(storesByDistance);

    if (storesByDistance.length && !selectedStoreNumber) {
      setSelectedStoreNumber(storesByDistance[0].storeNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, state, storesByDistance, storesBySelection, zipcode]);

  const onSuccess = async () => {
    suppressConfirmationModal();
  };

  const onContinue = (storeNumber: string) => {
    const storeResponse = results?.find((store) => store.storeId === storeNumber);
    if (storeResponse) {
      localizeCarryout(storeResponse);
    }
  };

  const onEditClick = () => {
    if (onEdit) onEdit();
    switchToCarryout();
    analytics.push(() => storeResultsViewButtonAnalytics('Carryout', 'Edit location'));
  };

  const onSwitchClick = () => {
    if (onEdit) onEdit();
    switchToDelivery();
    analytics.push(() => storeResultsViewButtonAnalytics('Carryout', 'Change to delivery'));
  };

  const reorderStoresOnMarkerClick = (marker: { lat: number; lng: number; storeNumber: string }) => {
    const selectedStoreIndex = storesByDistance.findIndex(
      ({ storeNumber }) => storeNumber === marker.storeNumber
    );
    const selectedStore = storesByDistance[selectedStoreIndex];

    const updatedStores = storesByDistance.filter(
      (storeDetail) => storeDetail.storeNumber !== selectedStore.storeNumber
    );
    updatedStores.unshift(selectedStore);

    setStoresBySelection(updatedStores);
    setSelectedStoreNumber(marker.storeNumber);
    setExpanded('store-tile-0');

    storesNearRef?.current?.scrollIntoView();
  };

  const selectStoreTile = (panel: string, isExpanded: string, storeNumber: string) => {
    setExpanded(isExpanded ? panel : '');
    setSelectedStoreNumber(storeNumber);
  };

  return (
    <>
      <Grid item data-testid="carryout-info" className={`${classes.storesNear} ${classes.content}`} ref={storesNearRef}>
        Stores near {zipcode || (city && state && `${city}, ${state}`) || 'me'}
      </Grid>
      <Grid className={classes.content} item>
        <LinkButton testId="edit-location" onClick={onEditClick}>Edit location</LinkButton>
        <span className={classes.line}>|</span>
        <LinkButton testId="change-occasion" onClick={onSwitchClick}>Change to delivery</LinkButton>
      </Grid>
      <Grid item className={classes.mapContainer}>
        <Map
          mapId="carryout-map"
          stores={storesByDistance}
          onMarkerClick={reorderStoresOnMarkerClick}
          highlightedMarker={selectedStoreNumber}
        />
      </Grid>
      <Grid container direction="column" spacing={0} className={`${classes.container} ${classes.content}`} data-testid="carryoutResults">
        <Grid wrap="nowrap" item container className={classes.storeGroup} direction="column" alignItems="stretch">
          {
            storesBySelection.map((storeDetail, index) => (
              <Grid item xs={12} key={index} className={classes.storeTile}>
                <StoreTile
                  index={index}
                  store={storeDetail}
                  onContinue={onContinue}
                  selectStoreTile={selectStoreTile}
                  expandState={expanded}
                  expandable
                  onSwitchOccasion={onSwitchClick}
                  occasion={Occasion.CARRYOUT}
                />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  const carryoutState = state.presentational.localization.previousSearchDetails.state;
  const {
    zipcode, city
  } = state.presentational.localization.previousSearchDetails;
  return {
    zipcode,
    city,
    state: carryoutState,
    storesByDistance: state.domain.localization.stores
  };
};

const mapDispatchToProps = {
  selectCarryoutStore,
  switchToCarryout,
  switchToDelivery
};

export default connect(mapStateToProps, mapDispatchToProps)(CarryoutResults);

import React, { useEffect, useRef } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { Grid } from '@material-ui/core';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import QuantityPicker from './QuantityPicker';
import styles from '../styles';

const BASE_ANALYTICS_EVENT = {
  event: 'FFE-QuantityPicker',
  event_category: 'Feature Flag Experiment'
};

// all these props are optional because the data returned from useCartItem can be undefined. ideally we should not have to do this.
interface QuantityPickerWrapperProps {
  quantity?: number;
  shouldShowQuantityPicker?: boolean;
  increaseQuantityButtonDisabled?: boolean;
  handleIncreaseItemQuantity?(): Promise<void>;
  handleDecreaseItemQuantity?(): Promise<void>;
}

const QuantityPickerWrapper = ({
  quantity,
  shouldShowQuantityPicker,
  handleIncreaseItemQuantity,
  handleDecreaseItemQuantity,
  increaseQuantityButtonDisabled
}: QuantityPickerWrapperProps): JSX.Element | null => {
  const [{ enabled: quantityPickerTrackingEnabled }] = useDecision('dtg-1217_cart_quantity_picker_tracking');
  const [{ enabled: quantityPickerExperimentEnabled, variationKey: quantityPickerExperimentvariationKey }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');
  const analytics = useAnalytics();
  const hasFiredAnalyticsEvent = useRef(false);
  const classes = styles.cartItem();
  const isQuantityPickerRenderable = handleIncreaseItemQuantity && handleDecreaseItemQuantity;

  useEffect(() => {
    if (
      !isQuantityPickerRenderable
      || !quantityPickerTrackingEnabled
      || !quantityPickerExperimentEnabled
      || hasFiredAnalyticsEvent.current
    ) return;
    if (quantityPickerExperimentvariationKey === 'control_qty_pick_pizza_deal_on') {
      analytics.push(() => ({
        ...BASE_ANALYTICS_EVENT,
        beacon_id: '99.07.02',
        event_action: 'Treated_QuantityPicker'
      }));
    } else {
      analytics.push(() => ({
        ...BASE_ANALYTICS_EVENT,
        beacon_id: '99.08.02',
        event_action: 'Untreated_QuantityPicker'
      }));
    }
    hasFiredAnalyticsEvent.current = true;
  }, [
    isQuantityPickerRenderable,
    analytics,
    quantityPickerTrackingEnabled,
    quantityPickerExperimentEnabled,
    quantityPickerExperimentvariationKey
  ]);

  if (!shouldShowQuantityPicker || !isQuantityPickerRenderable) {
    return null;
  }

  return (
    <Grid item xs={4} sm={7} className={classes.quantityItem}>
      <QuantityPicker
        quantity={quantity || 0}
        increaseItemQuantity={
          increaseQuantityButtonDisabled
            ? undefined
            : () => handleIncreaseItemQuantity()
        }
        decreaseItemQuantity={() => handleDecreaseItemQuantity()}
      />
    </Grid>
  );
};

export default QuantityPickerWrapper;

import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Grid, InputLabel, makeStyles, Hidden, IconButton, Button, CircularProgress
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from 'react-hook-form';
import OutlineTextInput from '../../common/OutlineTextInput';
import {
  getInputsFromGooglePlace,
  getCityStateZipFromCarryoutInput,
  carryoutToString
} from '../common/utils';
import {
  initGoogleAutoSuggest,
  removeGoogleAutocomplete,
  geocodeGooglePlace
} from '../common/google/autoComplete';
import CurrentLocationIcon from '../icons/CurrentLocationIcon';
import CurrentLocationMobileIcon from '../icons/CurrentLocationMobileIcon';
import LinkButton from '../../common/LinkButton';
import { onSubmitHandler } from './OccasionForm';
import fontStyles from '../../common/fontStyles';
import {
  USE_LOCATION_LABEL, 
  MOBILE_USE_LOCATION_LABEL,
  CARRYOUT_STORE_SEARCH_INPUT_NAME,
  CARRYOUT_STORE_ERROR_MESSAGES
} from './constants';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { handleGeoLocationError, resetPrefillInput } from '@/localization/actions';
import useLocalizationRail from '@/clientCore/localization/localizationRail/useLocalizationRail';
import { DiningOccasion } from '@pizza-hut-us-development/client-core';
import getGeoLocation from '@/localization/common/geoLocation/getGeoLocation';

const useStyles = makeStyles({
  content: {
    width: '100%'
  },
  occasion: {
    paddingBottom: '10px'
  },
  inputLabel: {
    ...fontStyles.formFieldLabel,
    lineHeight: 1.43,
    display: 'inline'
  },
  useMyLocationSection: {
    float: 'right',
    position: 'relative',
    padding: '0px 10px 5px 7px'
  },
  useMyLocationIcon: {
    position: 'relative',
    top: '2px'
  },
  useMyLocationMobIcon: {
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    }
  },
  validationError: {
    ...fontStyles.errorText,
    marginTop: '4px'
  },
  search: {
    paddingTop: '10px'
  }
});


type autoCompleteType = {
  getPlace: () => {
    place_id: string,
    address_components: {
      short_name: string, types: string[]
    }[]
  }
};

type onFormChangeType = (options: CarryoutSearchDetails) => void;
type searchCarryoutType = ({location}: CarryoutStoresLookup) => void;

export const onPlaceChanged = async (
  autocomplete: autoCompleteType,
  onFormChange: onFormChangeType,
  carryoutStoresLookupCallback: searchCarryoutType
) => {
  const placeDetails = autocomplete.getPlace();
  const { city, state } = getInputsFromGooglePlace(placeDetails);
  const { lat, lng } = await geocodeGooglePlace(placeDetails.place_id);  
  carryoutStoresLookupCallback({
    location: {city, state, lat, lng}
  });
  // update input field with city, state selection
  onFormChange({
    city,
    state,
    lat,
    lng
  });
};

interface CarryoutFormFieldsProps {
  searchCarryoutV2: ({location, invalidLocationCallback}: CarryoutSearchByLocation) => void;
  searchCarryoutByLatLongV2: ({invalidLocationCallback}: CarryoutSearch) => void;
  isSearchingIndicator: boolean;
  initialCarryoutState: CarryoutSearchDetails;
  switchToSavedAddresses: () => void;
  showUseSavedAddressButton: boolean;
}

interface CarryoutStoresLookup {
  location?: CarryoutSearchDetails,
  isGPSLocation?: boolean
}

export default function CarryoutFormFields(props: CarryoutFormFieldsProps) {
  const {
    isSearchingIndicator,
    initialCarryoutState,
    switchToSavedAddresses,
    showUseSavedAddressButton
  } = props;

  const cityStateId = 'carryout-city-state-zip';

  const dispatch = useDispatch();
  const shouldPrefillInput = useSelector(localizationSelectors.shouldPrefillInput);
  const localizedStoreAddress = useSelector(localizationSelectors.currentlyLocalizedAddress);
  const [displayCaliDeliveryWarningDecision] = useDecision('ops_dtg436_display_cali_delivery_warning');

  const { isLoading, setGpsLoading, handleStoreSearch } = useLocalizationRail();

  let carryoutAddressInitialState:  CarryoutSearchDetails;
  if(displayCaliDeliveryWarningDecision.enabled && shouldPrefillInput) {
    carryoutAddressInitialState = localizedStoreAddress;
    dispatch(resetPrefillInput());
  } else {
    carryoutAddressInitialState = initialCarryoutState; 
  }
  
  const [autoCompleteActive, setAutoCompleteActive] = useState(false);
  const [autoInput, setAutoInput] = useState(false);
  const [carryoutAddress, setCarryoutAddress] = useState(carryoutAddressInitialState);
  const [autocompleteListener, setAutocompleteListener] = useState<EventListener | null>(null);
  const classes = useStyles();
  const {
    handleSubmit, register, errors, clearErrors, setError
  } = useForm();

  const carryoutAddressValue = carryoutToString(carryoutAddress);
  const isValidationError = !!errors?.cityStateZip?.type;
  const router = useRouter(); 

  const {
    INVALID_ZIPCODE,
    EMPTY_CITY_STATE_ZIPCODE
  } = CARRYOUT_STORE_ERROR_MESSAGES;

  const handleCarryoutStoresGPSLookup = useCallback(async () => {
      try {
        setGpsLoading(true);
        const { lat, lng } = await getGeoLocation();
        handleStoreSearch({
          occasionId: DiningOccasion.CARRYOUT,
          latitude: `${lat}`,
          longitude: `${lng}`,
        });
      } catch (e) {
        handleGeoLocationError(dispatch);
      } finally {
        setGpsLoading(false);
      }
  }, []); 

  /**
   * @function handleCarryoutStoresLookup
   * @description handles the lookup for available carryout stores based on the city, state, zipcode OR GPS location.
   */
  const handleCarryoutStoresLookup = useCallback(async ({location = undefined, isGPSLocation = false}: CarryoutStoresLookup) => {
    if (isGPSLocation) {
      await handleCarryoutStoresGPSLookup();
    } else {
      handleStoreSearch({
        occasionId: DiningOccasion.CARRYOUT,
        city: location?.city,
        state: location?.state,
        postalCode: location?.zipcode
      })
    }
  }, []);

  const onFormChange = (carryoutInput: CarryoutSearchDetails) => {
    setCarryoutAddress(carryoutInput);
  };

  async function onFormChangeHandler(input: string) {
    const { city, state, zipcode } = getCityStateZipFromCarryoutInput(input);  
    onFormChange({ city, state, zipcode });

    const isNumeric = (char: string) => /\d/.test(char);
  
    if (input.length > 2 && !isNumeric(input.charAt(0))) {
      // initialize autocomplete only once to avoid duplicate dropdowns 
      if (!autoCompleteActive) {
        const options = {
          inputId: cityStateId,
          types: '(cities)',
          onPlaceChanged,
          onFormChange,
          searchAction: handleCarryoutStoresLookup
        };  
        const listener = initGoogleAutoSuggest(options);  
        setAutocompleteListener(listener);  
        setAutoCompleteActive(true);
      }
    }
  }

  function validateCityStateZip(input: string): (string | true) {
    const trimmedInput = input.trim();

    let isCityState;
    const isZip = /^\d{5}$/.test(trimmedInput);
    const containsUsa = /USA/i.test(trimmedInput);
    const isNumberOnly = /^\d+$/.test(trimmedInput);

    if (containsUsa) {
      // eslint-disable-next-line no-useless-escape
      isCityState = /^[\w\s\.]+,[\w\s\.]+, USA$/i.test(trimmedInput);
    } else {
      // eslint-disable-next-line no-useless-escape
      isCityState = /^[\w\s\.]+,[\w\s\.]+$/i.test(trimmedInput);
    }

    if (!isZip && !isCityState && !input.includes('store-')) {
      if (!isZip && isNumberOnly) {
        return INVALID_ZIPCODE;
      }
      return EMPTY_CITY_STATE_ZIPCODE;
    }

    return true;
  }

  const doesCityStateInputValueMatchState = (
    inputValue: { city?: string, state?: string }  
  ) => (
    carryoutAddress.city === inputValue.city
    && carryoutAddress.state === inputValue.state
  );  

  const onSubmit = (formData: Record<string, string>, event: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    onSubmitHandler(event, () => {
      const inputFieldValue = getCityStateZipFromCarryoutInput(formData.cityStateZip);

      /*
       * When a city, state is selected from the Google Autocomplete dropdown, the api replaces the
       * input field with a new value without triggering a change event in the input field.
       * Therefore, what we have in the state will differ from the actual value in the input field.
       * If the values do not match, then that implies that the Google API has taken over, and the
       * callback will do the carryout search.
       */
      if (doesCityStateInputValueMatchState(inputFieldValue) || inputFieldValue.zipcode) {
        const { city, state, zipcode } = inputFieldValue;

        handleCarryoutStoresLookup({
          location: {city, state, zipcode}
        });
      }
    });
  };

  const onGPSClick = () => {
    clearErrors();

    handleCarryoutStoresLookup({
      isGPSLocation: true
    });
  };

  useEffect(() => () => {
    if (autocompleteListener) {
      removeGoogleAutocomplete(autocompleteListener)
    }
  }, [autocompleteListener, setAutoInput, autoInput]);

  if (router.query?.local?.length === 5 && !autoInput && !carryoutAddressValue) {
    setCarryoutAddress({zipcode: router.query.local as string});

    handleCarryoutStoresLookup({
      location: {zipcode: router.query.local as string}
    });
    setAutoInput(true);  
  }

  const submitButton = (
    <Button data-testid="search" type="submit" variant="contained" color="primary" disableRipple fullWidth>
      {isSearchingIndicator || isLoading ? <CircularProgress size={24} /> : 'Search'}
    </Button>
  );

  return (
    <form className={classes.content} onSubmit={handleSubmit(onSubmit)}>
      <Grid item className={classes.occasion} xs={12}>
        <InputLabel className={classes.inputLabel} htmlFor={cityStateId}>
          City, state or zip code
        </InputLabel>
        <Hidden mdDown>
          <Grid className={classes.useMyLocationSection}>
            <LinkButton
              testId="desktop-location-icon"
              aria-label={USE_LOCATION_LABEL}
              onClick={onGPSClick}
              startIcon={<CurrentLocationIcon className={classes.useMyLocationIcon} />}
            >Use GPS
            </LinkButton>
          </Grid>
        </Hidden>
        <OutlineTextInput
          onChange={onFormChangeHandler}
          id={cityStateId}
          name={CARRYOUT_STORE_SEARCH_INPUT_NAME}
          inputRef={register({
            required: true,
            validate: validateCityStateZip
          })}
          error={isValidationError}
          testId="carryout-city-state-zip"
          defaultValue={carryoutAddressValue}
          icon={{
            endAdornment: (
              <Hidden lgUp>
                <InputAdornment position="start">
                  <IconButton
                    data-testid="mobile-location-icon"
                    aria-label={MOBILE_USE_LOCATION_LABEL}
                    classes={{ root: classes.useMyLocationMobIcon }}
                    disableRipple
                    onClick={onGPSClick}
                    edge="end"
                  >
                    <CurrentLocationMobileIcon />
                  </IconButton>
                </InputAdornment>
              </Hidden>
            )
          }}
        />
        {errors?.cityStateZip?.type === 'required' && (
          <div className={classes.validationError} data-testid="carryout-citystatezip-required">
            Enter a valid city, state or try a zip code
          </div>
        )}
        {errors?.cityStateZip?.type === 'validate' && (
          <div className={classes.validationError} data-testid="carryout-citystatezip-validate">
            {errors.cityStateZip.message}
          </div>
        )}
        {errors?.cityStateZip?.type === 'invalid-location' && (
          <div className={classes.validationError} data-testid="carryout-citystatezip-invalid-location">
            {errors.cityStateZip.message}
          </div>
        )}  
      </Grid>
      {showUseSavedAddressButton
        && (
          <Grid>
            <LinkButton
              testId="switchto-saved-address"
              onClick={switchToSavedAddresses}
            >
              Use a saved address
            </LinkButton>
          </Grid>
        )}
      <Grid className={classes.search} container item xs={12} alignItems="flex-end">
        {submitButton}
      </Grid>
    </form>
  );
}

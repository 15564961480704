/* eslint-disable max-classes-per-file */
import {
  format,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday
} from 'date-fns';
import { uniq } from 'remeda';
import { StorePaymentType } from '@pizza-hut-us-development/client-core';
import { Occasion, OccasionApi } from '@/localization/constants';
import determineCloseTime from '../../localization/common/determineCloseTime';
import { StoreStatus } from '@/localization/localizationRail/StoreTile/constants';
import convertTo12HourClock from '../../localization/common/convertTo12HourClock';
import getContactlessStatus from '../../localization/common/getContactlessStatus';
import logger from '../../common/logger';
import {
  ContactType,
  PhdApiStore,
  PhdApiStoreDays,
  PHDApiStoreHourAvailability,
  PhdApiStoreOccasions,
  PHDApiStorePaymentType,
  PHDApiStorePaymentTypeName
} from '@/api/phdApiClient/types';
import getOpeningHours from '@/localization/common/getOpeningHours';

/* eslint camelcase: "off" */

export enum OnlineStatus {
  INACTIVE = 'inactive',
  ONLINE = 'online',
  TEMP_OFFLINE = 'temp_offline',
  TEMP_CLOSE = 'temp_close'
}

export enum PosType {
  NPC = '1',
  SUS = '2'
}

export class CustomerDetailsException extends Error {}
export class StoreSpecificSearchException extends Error {}

export interface ProgramResponse {
  program_id: string;
}
export interface ContactResponse {
  type: ContactType;
  value: string;
}
export interface PaymentResponse {
  is_default: boolean;
  metadata: {
    balance: number;
    expiration: string;
    gateway: string;
    is_expired: boolean;
    last_four: string;
    number: string;
    pin: string;
    postal_code: string;
    type: string;
  };
  name: string;
  type: 'creditcard' | 'giftcard' | 'tpgc';
  payment_id: string;
}
export interface PromotionResponse {
  promotion_id: string;
  postal_code: string;
  contact_methods: ContactResponse[];
  birth_date: Date;
}
export interface CustomerAddressResponse {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postal_code: string;
  state: string;
  city: string;
  company: string;
  customer_address_id: string;
  is_default: boolean;
  name: string;
  type: string;
  delivery_instructions: string;
  phone: string;
}
export interface CustomerDetailsResponse {
  crm_id: string;
  do_not_sell_flag: boolean;
  loyalty_token: string;
  promotions: PromotionResponse[];
  programs: ProgramResponse[];
  customer_addresses: CustomerAddressResponse[];
  customer_id: string;
  first_name: string;
  last_name: string;
  email: string;
  payments: PaymentResponse[];
  phone: string;
  phone_extension: string;
}

export interface CustomerDetailsError {
  message: string;
}

export const isCustomerDetailsError = (
  response: CustomerDetailsResponse | CustomerDetailsError
): response is CustomerDetailsError => 'message' in response;

export function transformCustomerDetails({
  response,
  status
}: {
  response: CustomerDetailsResponse | CustomerDetailsError;
  status: number;
}): CustomerDetails {
  if (status !== 200) {
    const error = new CustomerDetailsException(
      `Unexpected response: ${
        (response as CustomerDetailsError)?.message
      } (status: ${status})`
    );
    logger.withoutTelemetry.error(error.message);
    throw error;
  }
  const custDetailsResponse = response as CustomerDetailsResponse;
  const promotions = custDetailsResponse?.promotions;
  const hutRewardsMember = !!custDetailsResponse?.programs.find(
    (program) => program.program_id === '1'
  );
  const hutLoversMarketingOptIn = !!promotions?.find((promotion) => promotion.contact_methods?.find((contact) => contact.type === 'EMAIL'));
  const birthdaySent = !!promotions?.find(
    (promotion) => !!promotion.birth_date
  );
  const textOptIn = !!promotions?.find((promotion) => promotion?.contact_methods?.find((contact) => contact.type === 'TEXT'));
  const savedAddresses = transformSavedAddresses(
    custDetailsResponse?.customer_addresses
  );

  return {
    id: custDetailsResponse.customer_id,
    firstName: custDetailsResponse.first_name,
    hutRewardsMember,
    savedAddresses,
    hutLoversMarketingOptIn,
    birthdaySent,
    textOptIn
  };
}

export function transformSavedAddresses(
  customerAddresses: CustomerAddressResponse[] = []
): SavedAddress[] {
  return customerAddresses.map(
    (address: CustomerAddressResponse, index: number) => ({
      address: address.address1,
      addressTwo: address.address2,
      zipcode: address.postal_code,
      state: address.state,
      city: address.city,
      name: address.name,
      index
    })
  );
}

export function transformStoreDetail(
  response: PhdApiStore,
  status: number,
  storeToken: string,
  occasion: Occasion
): ClientResult<StoreDetail> {
  if (status !== 200) {
    throw new StoreSpecificSearchException(
      `Unexpected response for store-specific information (status: ${status})`
    );
  }

  const storeWithToken = { ...response, token: storeToken };
  const transformedStoreDetail = transformPHDStoreToStoreDetail(
    storeWithToken,
    occasion
  );

  return { result: transformedStoreDetail, error: false };
}

type HasOccasion = {
  occasion_id: string;
};

export function getPaymentTypesForOccasion(
  paymentTypes: PHDApiStorePaymentType[],
  occasion: OccasionApi
): PHDApiStorePaymentTypeName[] {
  const availablePaymentTypes: PHDApiStorePaymentTypeName[] = [];

  paymentTypes.forEach((paymentType) => {
    if (paymentType.occasions.includes(occasion)) {
      availablePaymentTypes.push(paymentType.name);
    }
  });

  return availablePaymentTypes;
}

export function getAcceptedCCTypesForOccasion(
  paymentTypes: PHDApiStorePaymentType[] | StorePaymentType[],
  occasion: OccasionApi
): AcceptedCCTypes[] | string[] {
  const ccPaymentMetadata = (paymentTypes as PHDApiStorePaymentType[]).filter(
    (paymentType: PHDApiStorePaymentType) => paymentType.name === 'CREDITCARD'
      && paymentType.occasions.includes(occasion)
  );

  return (ccPaymentMetadata?.[0] as unknown as StorePaymentType)?.metadata?.acceptedCcTypes || [];
}

const getTimes = (
  day: string,
  dayOfWeek: number,
  availableDaysForOccasion: PHDApiStoreHourAvailability[],
  promiseTime = 0,
  storeTimezone: string,
  availableDayIntervals: PHDApiStoreHourAvailability[],
  isToday = false
): Date[] => {
  const intervalMap = availableDayIntervals.map((interval) => getOpeningHours(
    day,
    interval.interval_start_time,
    interval.duration,
    promiseTime,
    isToday,
    storeTimezone
  ));

  return intervalMap
    .flat()
    .sort(
      (a, b) => new Date(`1970/01/01 ${a}`).valueOf()
        - new Date(`1970/01/01 ${b}`).valueOf()
    );
};

const getAvailability = (
  nextDay: (date: Date | number) => Date,
  dayNumberOfTheWeek: number,
  availableDaysForOccasion: PHDApiStoreHourAvailability[],
  promiseTime: number | undefined,
  storeTimezone: string,
  availableDayIntervals: PHDApiStoreHourAvailability[]
) => {
  const deliveryOrCarryOutDate = `${format(
    nextDay(Date.now()),
    'EEEE'
  )}, ${format(nextDay(Date.now()), 'LLLL dd, yyyy')}`;

  return {
    label: deliveryOrCarryOutDate,
    value: format(nextDay(Date.now()), 'LL/dd/yyyy'),
    dateWithTime: getTimes(
      deliveryOrCarryOutDate,
      dayNumberOfTheWeek,
      availableDaysForOccasion,
      promiseTime,
      storeTimezone,
      availableDayIntervals,
      false
    )
  };
};

const DAY_AVAILABILITY = {
  [PhdApiStoreDays.Sunday]: nextSunday,
  [PhdApiStoreDays.Monday]: nextMonday,
  [PhdApiStoreDays.Tuesday]: nextTuesday,
  [PhdApiStoreDays.Wednesday]: nextWednesday,
  [PhdApiStoreDays.Thursday]: nextThursday,
  [PhdApiStoreDays.Friday]: nextFriday,
  [PhdApiStoreDays.Saturday]: nextSaturday
};

interface IntervalsByDay {
  [key: number]: PHDApiStoreHourAvailability[];
}

export function getDaysAvailableForSchedule(
  hours: PHDApiStoreHourAvailability[],
  occasion: OccasionApi,
  promiseTime: number | undefined,
  storeTimezone: string
): DayAvailableForSchedule[] {
  const todayDayOfWeek = format(Date.now(), 'EEEE');
  const numberTodayDayOfWeek = parseInt(format(Date.now(), 'i'), 10);
  const availableDaysForOccasion = hours.filter(
    (oc) => oc.occasion_id === occasion
  );

  const availableDays = availableDaysForOccasion.reduce<PhdApiStoreDays[]>(
    (p, c) => [...p, ...c.days],
    []
  );

  const availableDaysToIntervals: IntervalsByDay = availableDaysForOccasion.reduce((daysToIntervals, day) => {
    const intervalsByDay: IntervalsByDay = { ...daysToIntervals };
    day.days.forEach((dayOfWeek) => {
      const value = {
        ...day
      };

      if (intervalsByDay[dayOfWeek]) {
        intervalsByDay[dayOfWeek].push(value);
      } else {
        intervalsByDay[dayOfWeek] = [value];
      }
    });
    return intervalsByDay;
  }, {});

  const daysAvailableForSchedule = uniq(availableDays).map((availableDay) => {
    const nextDay = DAY_AVAILABILITY[availableDay];

    if (!nextDay) throw new Error(`unexpected PhdApiStoreDays, received: ${availableDay}`);

    return getAvailability(
      nextDay,
      availableDay,
      availableDaysForOccasion,
      promiseTime,
      storeTimezone,
      availableDaysToIntervals[availableDay]
    );
  });

  if (availableDays.includes(numberTodayDayOfWeek)) {
    const deliveryOrCarryOutDate = `${todayDayOfWeek}, ${format(
      Date.now(),
      'LLLL dd, yyyy'
    )}`;

    daysAvailableForSchedule.push({
      label: deliveryOrCarryOutDate,
      value: format(Date.now(), 'LL/dd/yyyy'),
      dateWithTime: getTimes(
        deliveryOrCarryOutDate,
        numberTodayDayOfWeek,
        availableDaysForOccasion,
        promiseTime,
        storeTimezone,
        availableDaysToIntervals[numberTodayDayOfWeek],
        true
      )
    });
  }

  const sortWeekdays = (
    a: DayAvailableForSchedule,
    b: DayAvailableForSchedule
  ) => new Date(a.value).getTime() - new Date(b.value).getTime();
  return daysAvailableForSchedule.sort(sortWeekdays);
}

const getMaxOrderLimit = (occasions: PhdApiStoreOccasions[]) => occasions.map((occasion) => ({
  name: occasion.name,
  maxOrder: occasion.max_order
}));

const isAllowTip = (occaison: PhdApiStoreOccasions[]) => occaison.map((occasion) => ({
  occasionName: occasion.name,
  isAllowed: occasion.allow_tip ? occasion.allow_tip : false
}));

export function transformPHDStoreToStoreDetail(
  store: PhdApiStore,
  occasion: Occasion
): StoreDetail {
  const carryoutPromiseTime = store.occasions.find(
    (oc: HasOccasion) => oc.occasion_id === 'CARRYOUT'
  )?.service_time;
  const deliveryPromiseTime = store.occasions.find(
    (oc: HasOccasion) => oc.occasion_id === 'DELIVERY'
  )?.service_time;

  const isCarryout = occasion === Occasion.CARRYOUT || (occasion as string) === 'CARRYOUT';

  const occasionFilter = isCarryout ? 'CARRYOUT' : 'DELIVERY';

  const [shift1, shift2] = store.hours.filter(
    (hour: HasOccasion) => hour.occasion_id === occasionFilter
  );

  const getOpenCloseTime = (shift: PHDApiStoreHourAvailability) => {
    const dayOfWeekOrdinal = parseInt(format(Date.now(), 'i'), 10);
    let shiftOpenTime: boolean | string = false;
    let shiftCloseTime: boolean | string = false;

    if (shift?.days.includes(dayOfWeekOrdinal)) {
      shiftOpenTime = convertTo12HourClock(shift?.interval_start_time);
      shiftCloseTime = convertTo12HourClock(determineCloseTime(shift) || '');
    }

    return { openTime: shiftOpenTime, closeTime: shiftCloseTime };
  };
  const shift1OpenCloseTime = getOpenCloseTime(shift1);
  const shift2OpenCloseTime = getOpenCloseTime(shift2);

  const getStoreStatus = (): string => {
    if (
      store.status === StoreStatus.TEMP_CLOSED
      && store.online_status === OnlineStatus.INACTIVE
    ) {
      return StoreStatus.INACTIVE;
    }

    return store.status || StoreStatus.ONLINE_BUT_OUTSIDE_STORE_HOURS;
  };

  let posType;
  if (store.metadata?.pos_type === PosType.NPC) {
    posType = 'NPC';
  } else if (store.metadata?.pos_type === PosType.SUS) {
    posType = 'SUS';
  }

  const promiseTime = isCarryout ? carryoutPromiseTime : deliveryPromiseTime;

  const paymentTypesForOccasion = store?.payment_types
    ? getPaymentTypesForOccasion(
      store.payment_types,
      occasionFilter as OccasionApi
    )
    : [];

  const acceptedCCTypesForOccasion = store?.payment_types
    ? getAcceptedCCTypesForOccasion(
      store.payment_types,
      occasionFilter as OccasionApi
    )
    : [];

  const daysAvailableForSchedule = store?.hours
    ? getDaysAvailableForSchedule(
      store.hours,
      occasionFilter as OccasionApi,
      promiseTime,
      store.timezone
    )
    : [];

  const deliveryOccasions = store.occasions.filter(
    (oc: HasOccasion) => oc.occasion_id === OccasionApi.D
  );
  const deliveryOccasion = deliveryOccasions.length > 0 && deliveryOccasions[0];

  return {
    storeNumber: store.store_number,
    address: store.address1,
    city: store.city,
    state: store.state,
    zipcode: store.postal_code,
    phoneNumber: store.phone_number,
    landmark: store.landmark,
    dragonTail: store.dragontail ?? false,
    promiseTime,
    openTime: shift1OpenCloseTime.openTime,
    closeTime: shift1OpenCloseTime.closeTime,
    splitOpenTime: shift2OpenCloseTime.openTime,
    splitCloseTime: shift2OpenCloseTime.closeTime,
    storeStatus: getStoreStatus(),
    acceptFutureOrders: store.allows_future_orders,
    storeClosureReason: store.status_message,
    lat: store.latitude && parseFloat(store.latitude),
    long: store.longitude && parseFloat(store.longitude),
    storeToken: store.token,
    contactless: getContactlessStatus(store.metadata, occasion),
    posType,
    storeTimezone: store.timezone,
    carryoutPromiseTime: carryoutPromiseTime && carryoutPromiseTime * 60,
    deliveryPromiseTime: deliveryPromiseTime && deliveryPromiseTime * 60,
    deliveryMinOrder: deliveryOccasion ? deliveryOccasion?.min_order : 0,
    paymentTypesForOccasion,
    acceptedCCTypesForOccasion: acceptedCCTypesForOccasion as AcceptedCCTypes[],
    daysAvailableForSchedule,
    orderMaxForOccasion: getMaxOrderLimit(store.occasions),
    storeTipEligibility: isAllowTip(store.occasions),
    baseDeliveryCharge: store.base_delivery_charge,
    showSodiumAlerts: store.metadata?.show_sodium_alerts
  };
}

export const transformPromotion = (
  email: string,
  postalCode: string | undefined,
  includeEmailOptIn = false
): CustomerPromotions => {
  const emailNotification: NotificationType = {
    type: 'EMAIL',
    value: email
  };

  return {
    contact_methods: [emailNotification],
    promotion_id: '1',
    ...(includeEmailOptIn && { email_opt_in: true }),
    postal_code: postalCode ?? '',
    tos: true
  };
};

import {
  useState, useEffect, useMemo, useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartItem, Cart, PhdCart } from '@pizza-hut-us-development/client-core';
import {
  addOOSAlertItem,
  closeCartRail,
  setEditCartItemId
} from '@/clientCore/redux/rail/CartRailSlice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { useCartDeleteItem } from '@/clientCore/cart/hooks/useDeleteItem';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';
import { localizationSelectors } from '@/localization/localizationSelectors';
import {
  MAX_ORDER_LIMIT,
  CENTS_PER_DOLLAR, EDIT_IN_CART_TYPES
} from '@/clientCore/cart/components/CartRail/constants';
import { getMaxOrderLimitForOccasion } from '@/clientCore/cart/components/CartRail/hooks/useCartRail.helpers';
import useCCCartState from '@/clientCore/cart/hooks/useCCCartState';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { UseCartRail } from '@/clientCore/cart/components/CartRail/types';
import { toggleCartInfoAndWarningView } from '@/clientCore/redux/cart/CartSlice';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { onCartRailPageView, onCartRailProductClose } from '@/checkout/checkout.analytics';
import { CART_PRODUCT_CLOSE_LABEL_ANALYTICS, OOS_REASON } from '@/clientCore/cart/constants';
import { cartItemsToLegacy } from '@/clientCore/cart/helpers';
import { useGetCart } from '@/clientCore/cart/hooks/useGetCart';

export const useCartRail = (): UseCartRail => {
  // 7DL Pick 2 enforcement
  const [show7DLCheckoutWarning, setShow7DLCheckoutWarning] = useState(false);

  const {
    cart,
    isLocalized,
    isEmptyCart,
    isCartRailOpen,
    isEditingFlow,
    selectedItem
  } = useCCCartState();
  const showInfoAndWarningView = useSelector(
    presentationalCartSelectors.showCartInfoAndWariningView
  );
  const { handleDeleteCartItem } = useCartDeleteItem();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  // TODO: add discountedAmount to destructure below when type is added to YumCart
  const { items, subtotal } = useSelector(orderSelectors.cart) ?? {
    items: [],
    subtotal: 0,
    discountedAmount: 0
  };
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const occasion = useSelector(localizationSelectors.occasion);
  const cartChangedAlert = useSelector(presentationalRailSelectors.cartChangedAlert);
  const oosAlertItemList = useSelector(presentationalRailSelectors.oosAlertItemList);

  const [,{ getCart }] = useGetCart();

  const cartRailViewCount = useRef(0);
  useEffect(() => {
    if (isCartRailOpen) {
      cartRailViewCount.current += 1;
      analytics.push(() => onCartRailPageView(cartItemsToLegacy(items), (cartRailViewCount.current).toString()));
    }
    setShow7DLCheckoutWarning(false);
    // Exhaustive wants `items` in here, which has bad consequences
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartRailOpen, analytics]);

  useEffect(() => {
    if (cart?.failedItems?.type === 'Conflict') {
      cart.failedItems?.data?.forEach((conflict) => {
        const cartItem = cart?.items?.find((item) => item.cartItemId === conflict.lineItemId);
        if (conflict.reason === OOS_REASON && cartItem?.cartItemId && !oosAlertItemList?.some(
          (item) => item.lineItemId === cartItem.cartItemId
        )) {
          dispatch(addOOSAlertItem({
            lineItemId: conflict.lineItemId ?? '',
            name: cartItem.name
          }));
          // Refetch cart to get the latest cart data.
          // This is needed to ensure that the OOS items are removed from cart.
          getCart?.();
        }
      });
    }
  }, [dispatch, cart, oosAlertItemList, getCart]);

  const show7DLInlineWarning = useMemo(
    () => !!cart && !(cart as Cart & PhdCart)?.lineupReady,
    [cart]
  );

  // Our coupon component is not wired up to apply coupons as well as no type for YumCart
  const discountedAmount = 0;

  const showEditCart = isEditingFlow && selectedItem?.type === 'PRODUCT';

  const handleRemoveItem = (item: CartItem) => {
    handleDeleteCartItem(item);
  };

  const handleRailClose = () => {
    if (showEditCart) {
      dispatch(setEditCartItemId(null));
      analytics.push(() => onCartRailProductClose(CART_PRODUCT_CLOSE_LABEL_ANALYTICS));
    }
    dispatch(closeCartRail());
    // TODO: Flushing warning and other presentation state should be consolidated and handled elsewhere.
    dispatch(toggleCartInfoAndWarningView({ displayState: false }));
  };

  // Order delivery limit alert
  const subtotalLessDiscounts = subtotal
    ? subtotal - (discountedAmount === undefined ? 0 : discountedAmount)
    : 0;

  function checkIfAnyItemBeyondLimit(maxOrderLimit: number): boolean {
    return items?.some((item) => {
      if (item.price && item.quantity) {
        const singleItemPrice = item.price / item.quantity;
        return singleItemPrice + subtotalLessDiscounts > maxOrderLimit;
      }
      return subtotalLessDiscounts > maxOrderLimit;
    });
  }

  const maxOrderLimitForOccasion = getMaxOrderLimitForOccasion({
    maxOrderLimitsList: storeDetails?.orderMaxForOccasion,
    occasion
  });
  const hasItemBeyondMaxOrderLimit = checkIfAnyItemBeyondLimit(
    maxOrderLimitForOccasion
  );

  const isSubtotalOverTheMaxOrderLimit = subtotalLessDiscounts > maxOrderLimitForOccasion;
  const isSubtotalAtTheMaxOrderLimit = subtotalLessDiscounts === maxOrderLimitForOccasion;
  const shouldShowOrderLimitAlert = hasItemBeyondMaxOrderLimit
    || isSubtotalOverTheMaxOrderLimit
    || isSubtotalAtTheMaxOrderLimit;

  const getOrderLimitAlertMessage = (): string => {
    if (isSubtotalOverTheMaxOrderLimit) {
      return MAX_ORDER_LIMIT.warning.exceeded(
        `$${(maxOrderLimitForOccasion / CENTS_PER_DOLLAR).toFixed(2)}`
      );
    }
    if (isSubtotalAtTheMaxOrderLimit) {
      return MAX_ORDER_LIMIT.warning.reached;
    }
    if (hasItemBeyondMaxOrderLimit) {
      return MAX_ORDER_LIMIT.warning.approaching(
        `$${(maxOrderLimitForOccasion / CENTS_PER_DOLLAR).toFixed(2)}`
      );
    }
    return '';
  };

  const alertMessageText = getOrderLimitAlertMessage();

  return [
    {
      cart,
      isLocalized,
      showInfoAndWarningView,
      shouldShowOrderLimitAlert,
      alertMessageText,
      isEmptyCart,
      isCartRailOpen,
      showEditCart: isEditingFlow && !!selectedItem && EDIT_IN_CART_TYPES.includes(selectedItem.type),
      selectedItem,
      show7DLInlineWarning,
      show7DLCheckoutWarning,
      cartChangedAlert,
      oosAlertItemList
    },
    {
      handleRailClose,
      handleRemoveItem,
      setShow7DLCheckoutWarning
    }
  ];
};

import { useDecision } from '@optimizely/react-sdk';
import { DealBuilder } from '@pizza-hut-us-development/client-core';
import { Deal, DealData } from '../slice/dealTypes';
import {
  AddableCartItem,
  AddableCartItemKinds
} from '@/api/phdApiV2Client/request.types';
import { CartItemInfo } from '@/domain/cart/types';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import useCCGetDiscountFromMarketingCodeQuery
  from '@/clientCore/temporaryTransformationalHooks/useCCGetDiscountFromMarketingCodeQuery';
import useCCCartState from '@/clientCore/cart/hooks/useCCCartState';
// import {
// useCCGetMultipleDealBuildersQuery
// } from '@/clientCore/temporaryTransformationalHooks/useCCGetMultipleDealBuildersQuery';

export enum Status {
  Loading,
  Failure,
  Unavailable,
  AvailableInOtherOccasion
}

interface UseDeal {
  dealData: DealData | Status;
  rawData: DealBuilder | undefined;
}

export const isDealData = (
  value: DealData | Status
): value is DealData => !Status[value as unknown as keyof typeof Status];

export const isDealType = (
  item: AddableCartItem | CartItemInfo
): item is Deal => {
  const hasProperty = 'userSelections' in item;
  return hasProperty && item?.data.kind === AddableCartItemKinds.DEAL;
};

const useDeal = (dealId: string, marketingCode = '', isCallYumMarketingCodeQuery = false): UseDeal => {
  // const [subDealIds, setSubDealIds] = useState([]);
  // TODO: Wire this up to filter OOS sub-deals when on CC
  // useCCGetMultipleDealBuildersQuery({ itemIds: subDealIds });
  const [{
    enabled: isSingleStepPizzaDealQuantityPickerEnabled, variationKey
  }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');

  const isSingleStepDealQtyPickerABTestOn = isSingleStepPizzaDealQuantityPickerEnabled && variationKey === 'control_qty_pick_pizza_deal_on';
  const { selectedItem } = useCCCartState();

  const {
    data: ccData,
    rawData,
    loading
  } = useCCGetDealBuilderQuery({
    itemId: dealId,
    skip: isCallYumMarketingCodeQuery
  });

  const {
    data: ccMarketingDealData,
    loading: ccMarketingDealDataLoading
  } = useCCGetDiscountFromMarketingCodeQuery({
    marketingCode,
    skip: !isCallYumMarketingCodeQuery || !marketingCode
  });

  let data;
  if (ccData) {
    data = {
      deal: ccData
    };
  }
  if (ccMarketingDealData) {
    data = {
      deal: ccMarketingDealData
    };
  }

  if (loading || ccMarketingDealDataLoading) return { dealData: Status.Loading, rawData };

  if (!data) return { dealData: Status.Unavailable, rawData };

  const { deal } = data;

  let dealQuantity = deal?.quantity || 1;

  if (isSingleStepDealQtyPickerABTestOn && selectedItem?.id === dealId && selectedItem.quantity) {
    dealQuantity = selectedItem.quantity;
  }

  const modifiedDeal = {
    ...deal,
    quantity: dealQuantity,
    steps:
      deal?.steps.map((step) => ({
        ...step,
        outOfStock: step.outOfStock
      })) || []
  };

  const isDealUnavailableInBothOccasions = !modifiedDeal.available && !modifiedDeal.availableInOtherOccasion;
  if (
    !modifiedDeal
    || isDealUnavailableInBothOccasions
    || modifiedDeal.outOfStock
  ) {
    return { dealData: Status.Unavailable, rawData };
  }

  if (!modifiedDeal.available && modifiedDeal.availableInOtherOccasion) {
    return { dealData: Status.AvailableInOtherOccasion, rawData };
  }

  return { dealData: modifiedDeal, rawData };
};

export default useDeal;

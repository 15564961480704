import { makeStyles } from '@material-ui/core/styles';
import fontStyles from '@/common/fontStyles';
import { largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint } from '@/materialUi/theme';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    width: '100%',
    maxWidth: '728px',
    paddingTop: '16px',
    paddingBottom: '16px',
    gridTemplateColumns: '0fr auto',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: '24px'
    },
    '&:first-child': {
      marginTop: '24px'
    }
  },
  gridTemplateWhenNoDropdown: {
    gridTemplateAreas: `
      "image info"
      "image cta"
      "nutrition nutrition"
    `
  },
  gridTemplateForDropdown: {
    gridTemplateAreas: `
      "image info"
      "image info"
      "cta cta"
      "nutrition nutrition"
    `,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      gridTemplateAreas: `
      "image info"
      "image cta "
      "nutrition nutrition"
      `
    }
  },
  title: {
    ...fontStyles.productTitleMobile,
    fontSize: '14px',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      ...fontStyles.productTitle,
      fontSize: '16px',
      lineHeight: '1.5',
      paddingTop: 3
    }
  },
  price: {
    ...fontStyles.productPrice,
    marginTop: '5px'
  },
  imageContainer: {
    gridArea: 'image',
    background: 'white',
    display: 'flex',
    margin: '0 16px 0 16px',
    height: '84px',
    width: '72px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      margin: '0 24px 0 24px',
      height: '121px',
      width: '104px'
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
    display: 'block'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  soldOutContentContainer: {
    gridArea: 'soldoutinfo',
    justifyContent: 'normal',
    paddingRight: '16px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingRight: '24px'
    }
  },
  ctaContainer: {
    gridArea: 'cta',
    margin: '16px 16px 0 16px',
    display: 'grid',
    gridRowGap: '24px',
    gridColumnGap: '24px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      gridTemplateColumns: '1fr 1fr',
      margin: '16px 24px 0 0'
    }
  },
  sizeDropdownAndCtaButtonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gridColumn: '-2',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      alignItems: 'baseline'
    }
  },
  priceAndNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gridArea: 'info',
    padding: '0 16px 0px 0px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      padding: '0 24px 0px 0px'
    }
  },
  productInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'start'
  },
  infoIcon: {
    backgroundColor: 'transparent',
    padding: '0 0 0 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg > g g': {
        fill: '#131313'
      }
    }
  },
  soldOutCard: {
    display: 'grid',
    width: '100%',
    maxWidth: '728px',
    gridTemplateAreas: `
    "image soldoutinfo soldoutinfo soldoutinfo"
    "image . . ."
    `,
    gridTemplateColumns: 'auto 1fr',
    opacity: '0.5',
    padding: '16px 0',
    objectFit: 'contain',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.up(smallMobileStartBreakpoint)]: {
      padding: '24px 0'
    }
  },
  soldOutBadge: {
    '& > .badgeText': {
      display: 'inline-block'
    }
  },
  modifierDropdownWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  modifierDropdownLabel: {
    fontWeight: 600,
    marginBottom: '11px'
  },
  modifierDropdownSelectComponent: {
    width: '100%',
    height: '38px',
    borderRadius: '8px',
    border: '1px solid #717170',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      marginBottom: '0px'
    },
    '& svg': {
      color: '#000',
      marginRight: '4px'
    },
    '& select:focus': {
      backgroundColor: '#fff',
      borderRadius: '8px'
    }
  },
  modifierDropdownSelectElement: {
    height: '100%',
    padding: '0',
    lineHeight: 'inherit',
    paddingLeft: '15px'
  },
  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap', /* added line */
    border: '0'
  },
  nutritionInfoWrapper: {
    padding: '0 24px'
  },
  sodiumWarning: {
    marginTop: '3px'
  }
}));

export default useStyles;

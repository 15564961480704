import React, { createContext, useCallback, useState } from 'react';
import {
  CCStoreResponse, StoreSearchPayload, useStoreSearchMutation, DeliveryAddress, DiningOccasion,
  storeApiInfo
} from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';

import {
  carryoutOptionsFromPayload,
  deliveryAddressFromPayload,
  showCarryoutNoStoresFoundModal,
  showDeliveryNoStoresFoundModal
} from '@/clientCore/localization/helpers';
import telemetry from '@/telemetry';

export type LocalizationRailContextType = {
  showSearch: boolean;
  results?: CCStoreResponse[];
  deliveryAddress?: DeliveryAddress;
  isLoading?: boolean;
  setShowSearch: (value: boolean) => void;
  setGpsLoading: (value: boolean) => void;
  handleStoreSearch: (payload: StoreSearchPayload) => void;
};

export const LocalizationRailContext = createContext<LocalizationRailContextType | null>(null);

const LocalizationRailProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();

  const [showSearch, setShowSearch] = useState(true);
  const [storeSearch, storeSearchResult] = useStoreSearchMutation();
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const [gpsLoading, setGpsLoading] = useState(false);
  const [searchedStoreNumber, setSearchedStoreNumber] = useState('');

  const results = searchedStoreNumber
    ? storeSearchResult.data?.filter((store) => store.storeNumber === searchedStoreNumber)
    : storeSearchResult.data;
  const isLoading = storeSearchResult?.isLoading || gpsLoading;

  const searchSpecificStore = useCallback(async (storeNumber: string) => {
    const result = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(storeNumber));
    return {
      city: result.data.city,
      state: result.data.state,
      postalCode: result.data.postalCode
    };
  }, [dispatch]);

  const handleStoreSearch = useCallback(async (payload: StoreSearchPayload) => {
    let modifiedPayload = payload;

    // Support searching for a specific store when passing store-{store_number} as the city
    let storeNumber = '';
    if (payload.city?.includes('store-')) {
      storeNumber = payload.city.substring(payload.city.indexOf('-') + 1);
      const store = await searchSpecificStore(storeNumber);
      modifiedPayload = {
        ...payload,
        ...store
      };
    }
    setSearchedStoreNumber(storeNumber);

    const storeResults = await storeSearch(modifiedPayload);

    if (!('data' in storeResults)) {
      switch (payload.occasionId) {
        case DiningOccasion.DELIVERY:
          showDeliveryNoStoresFoundModal(dispatch, deliveryAddressFromPayload(payload));
          break;
        case DiningOccasion.CARRYOUT:
          showCarryoutNoStoresFoundModal(dispatch, carryoutOptionsFromPayload(payload));
          break;
        default: break;
      }
      return;
    }

    if (payload.occasionId === DiningOccasion.DELIVERY) {
      setDeliveryAddress(deliveryAddressFromPayload(payload, storeResults?.data?.[0]));
      telemetry.addCustomEvent('web2-search-delivery-stores');
    } else {
      setDeliveryAddress(undefined);
      telemetry.addCustomEvent('web2-search-carryout-stores');
    }

    setShowSearch(false);
  }, [dispatch, searchSpecificStore, storeSearch]);

  return (
    <LocalizationRailContext.Provider value={{
      showSearch,
      results,
      deliveryAddress,
      isLoading,

      setShowSearch,
      setGpsLoading,
      handleStoreSearch
    }}
    >
      {children}
    </LocalizationRailContext.Provider>
  );
};

export default LocalizationRailProvider;

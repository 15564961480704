enum StandaloneEndpoints {
  ENCRYPT_SC_LIST = '/api/encrypt-sc-list',

  CREATE_YUM_GUEST_TOKEN = '/api/create-yum-guest-token',
  CREATE_CC_CART_ID_COOKIE = '/api/create-cc-cart-id-cookie',
  CREATE_CC_LOCALIZATION_COOKIE = '/api/create-cc-localization-cookie',

  DELETE_SERVER_ENV = '/api/delete-server-env',
  DELETE_LOCALIZATION_COOKIE = '/api/delete-localization-cookie',

  GET_COOKIE_VALUE = '/api/get-cookie-value',
  GET_ACCESS_TOKEN_EXP_IN = '/api/get-access-token-expiration',

  PREPARE_YUM_GUEST_TO_CUSTOMER = '/api/prepare-yum-guest-to-customer',

  REFRESH_ACCESS_TOKEN = '/api/refresh-yum-access-token',

  REMOVE_YUM_TOKENS = '/api/remove-yum-tokens',

  SAVE_ACCESS_TOKEN = '/api/save-yum-access-token',
  SAVE_LOCALIZATION_TOKEN = '/api/save-localization-token',
  SAVE_SERVER_ENV = '/api/save-server-env',

  UPDATE_LOCALIZATION_COOKIE = '/api/update-localization-cookie',

  LOGOUT_YUM = '/api/logout-yum'
}

export default StandaloneEndpoints;

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CCStoreResponse, DeliveryAddress, DiningOccasion, LocalizeCustomerCartInput, OrderActions, storeApiInfo, useCreateCustomerCartMutation,
  useResetCustomerCartMutation
} from '@pizza-hut-us-development/client-core';

import { closeLocalizationRail, resetToNationalStore, setLegacyLocalizationState } from '@/localization/actions';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { RootState } from '@/rootStateTypes';
import sessionService from '@/services/sessionService';
import { localizationSelectors } from '@/localization/localizationSelectors';
import router from '@/router';
import telemetry from '@/telemetry';

const useLocalization = () => {
  const dispatch = useDispatch();

  const currentStore = useSelector(orderSelectors.store);
  const localizationOptions = useSelector(localizationSelectors.railOptions);

  const xServerEnv: string = useSelector(
    (state: RootState) => state.coreConfig.xServerEnv
  );

  const [createCartMutation] = useCreateCustomerCartMutation();
  const [resetCartMutation] = useResetCustomerCartMutation();

  useEffect(() => {
    localStorage.setItem('cc-x-server-env', xServerEnv);
    sessionService.saveServerEnv(xServerEnv);
  }, [xServerEnv]);

  const saveCartIdInCookie = useCallback(async (cartCCId: string) => {
    await standaloneApiClient.post(
      { cartCCId },
      StandaloneEndpoints.CREATE_CC_CART_ID_COOKIE
    );
  }, []);

  const saveLocalizationCookie = useCallback(async (payload: LocalizeCustomerCartInput) => {
    await standaloneApiClient.post(
      { payload },
      StandaloneEndpoints.CREATE_CC_LOCALIZATION_COOKIE
    );
  }, []);

  const saveStoreToken = useCallback(async (token?: string) => {
    await standaloneApiClient.post(
      { token },
      StandaloneEndpoints.SAVE_LOCALIZATION_TOKEN
    );
  }, []);

  const createCart = async (payload: LocalizeCustomerCartInput, onAfterCartLocalization: () => void, storeToken?: string) => {
    try {
      dispatch(toggleCartLoadingStatus({ loadingState: true }));
      if (currentStore && currentStore.storeNumber !== payload.storeNumber) {
        await resetCartMutation();
      }
      const response = await createCartMutation(payload);
      if ('data' in response) {
        await Promise.all([
          saveCartIdInCookie(response.data.cartId),
          saveLocalizationCookie(payload),
          saveStoreToken(storeToken)
        ]);
        onAfterCartLocalization();
      }
    } catch (error) {
      resetToNationalStore(dispatch);
    } finally {
      dispatch(toggleCartLoadingStatus({ loadingState: false }));
    }
  };

  const handlePostLocalization = (store: CCStoreResponse, occasion: DiningOccasion, deliveryAddress?: DeliveryAddress) => {
    if (occasion === DiningOccasion.DELIVERY) {
      telemetry.addCustomEvent('web2-select-delivery-store');
    } else {
      telemetry.addCustomEvent('web2-select-carryout-store');
    }

    // TODO: Remove after replacing legacy localization selectors in components
    setLegacyLocalizationState(dispatch, store, occasion, deliveryAddress);

    const { routeTo, handleAfterLocalizing } = localizationOptions;

    if (handleAfterLocalizing) {
      handleAfterLocalizing();
    }

    if (routeTo) {
      router.goToRoute(routeTo);
    }
  };

  const localizeCarryout = async (store: CCStoreResponse) => {
    dispatch(closeLocalizationRail());
    const { data: storeDetails } = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(store.storeNumber));

    const storeInfo = {
      ...storeDetails,
      token: store.token
    } as CCStoreResponse;

    dispatch(OrderActions.setStore(storeInfo));

    await createCart({
      storeNumber: store.storeNumber,
      occasion: DiningOccasion.CARRYOUT
    }, () => handlePostLocalization(storeInfo, DiningOccasion.CARRYOUT), storeInfo.token);
  };

  const localizeDelivery = async (store: CCStoreResponse, deliveryAddress: DeliveryAddress) => {
    dispatch(closeLocalizationRail());
    const { data: storeDetails } = await dispatch(storeApiInfo.endpoints.storeInfo.initiate(store.storeNumber));

    const storeInfo = {
      ...storeDetails,
      token: store.token
    } as CCStoreResponse;

    dispatch(OrderActions.setStore(storeInfo));

    await createCart({
      storeNumber: store.storeNumber,
      occasion: DiningOccasion.DELIVERY,
      deliveryAddress: {
        ...deliveryAddress,
        countryCode: 'US'
      }
    }, () => handlePostLocalization(storeInfo, DiningOccasion.DELIVERY, deliveryAddress), storeInfo.token);
  };

  return {
    localizeCarryout,
    localizeDelivery
  };
};

export default useLocalization;

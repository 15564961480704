// eslint-disable-next-line no-restricted-imports
import { useQuery, QueryResult } from '@apollo/client';
import { DocumentNode } from 'graphql';

export default function useSSRQuery(
  query: DocumentNode, params: any
) : QueryResult {
  const result = useQuery(query, { ...params, errorPolicy: 'none' });
  return { ...result };
}

enum AccountManagementEndpoints {
  GET_CUSTOMER = '/customer',
  UPDATE_CUSTOMER = '/customer',
  CREATE_CUSTOMER = '/customer',
  CUSTOMER_ADDRESS = '/customer/addresses',
  CUSTOMER_PAYMENTS = '/customer/payments',
  ESCALATE_PRIVILEGES = '/customer/privileged',
  UPDATE_PASSWORD = '/customer/password',
  UPDATE_PROMOTIONS = '/customer/promotions',
  CUSTOMER_LOYALTY = '/customer/loyalty',
  CUSTOMER_COUPON = '/customer/coupon'
}

export default AccountManagementEndpoints;

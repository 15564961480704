import React from 'react';
import { connect } from 'react-redux';
import CarryoutResults from './CarryoutResults';
import OccasionForm from './OccasionForm';
import { Occasion, ValidOccasion } from '../constants';
import DeliveryResults from './DeliveryResults';
import { showSearchInRail } from '../actions';
import useLocalizationRail from '@/clientCore/localization/localizationRail/useLocalizationRail';
import { useDecision } from '@optimizely/react-sdk';

interface LocalizationRailContentViewProps {
  occasion: Occasion;
  searchInRail: boolean;
  showSearchInRail: () => void;
  useSavedAddresses: boolean;
  switchToSavedAddresses: () => void;
  switchToFormFields: () => void;
}

const LocalizationRailContentView = (
  {
    occasion, searchInRail, showSearchInRail,
    useSavedAddresses, switchToSavedAddresses, switchToFormFields
  } : LocalizationRailContentViewProps
) => {
  const { showSearch, setShowSearch } = useLocalizationRail();

  const onEdit = () => {
    setShowSearch(true);
  };

  const resultsViews: { [key in ValidOccasion]: JSX.Element } = {
    [Occasion.DELIVERY]: <DeliveryResults onEdit={onEdit} />,
    [Occasion.CARRYOUT]: <CarryoutResults onEdit={onEdit} />
  };

  let contentView: React.ReactNode;

  if (showSearch) {
    contentView = (
      <OccasionForm
        switchToSavedAddresses={switchToSavedAddresses}
        switchToFormFields={switchToFormFields}
        useSavedAddresses={useSavedAddresses}
      />
    );
  } else {
    contentView = resultsViews[occasion as ValidOccasion];
  }

  return (
    <>
      {contentView}
    </>
  );
};

const mapStateToProps = (state : any) => ({
  searchInRail: state.presentational.localization.searchInRail,
  occasion: state.presentational.localization.occasion
});

const mapDispatchToProps = {
  showSearchInRail
};
export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRailContentView);

enum PhdApiEndpoints {
  STORE_SEARCH = '/stores/search',
  GET_STORE = '/stores/%s',
  GET_CUSTOMER_DETAILS = '/customer',
  POST_CUSTOMER = '/customer',
  REFRESH_LOCALIZATION_TOKEN = '/localization/refresh',
  LEGACY_GIFT_CARD_BALANCE = '/gift_card/balance',
  GET_CUSTOMER_PROMOTIONS = '/customer/promotions',
  POST_CUSTOMER_PROMOTIONS = '/customer/promotions',
  DELIVERY_ORDER_STATUS = '/orderStatus/%s',
  REMOVE_PAYMENT_FROM_ORDER = '/orders/payments',
  CARRYOUT_ORDER_STATUS = '/orders/%s/status'
}

export default PhdApiEndpoints;

import phdApiClient from '../../api/phdApiClient';

/* eslint camelcase: "off" */

const customerService: CustomerService = {
  getCustomerInfo(): Promise<CustomerDetails> {
    return phdApiClient.getCustomerInfo();
  },
  postCustomerPromotions(email, postalCode): Promise<boolean> {
    return phdApiClient.postCustomerPromotions(email, postalCode);
  },
  async getCustomerDetails(): Promise<CustomerDetails> {
    const customerInfo = await this.getCustomerInfo();

    return {
      ...customerInfo
    };
  },
  async getPromotionsAndVerifyExistingAccount(
    input?: string,
    isValid?: boolean
  ): Promise<{ exists: boolean; hasPromotions: boolean }> {
    if (input && isValid) {
      const customer = await phdApiClient.authentication.customerLookupWithContents(input);
      return {
        exists: customer.customer_exists,
        hasPromotions: customer.email_opt_in
      };
    }
    return {
      exists: false,
      hasPromotions: false
    };
  },
  async verifyExistingAccount(input?: string, isValid?: boolean): Promise<boolean> {
    return Boolean(input && isValid && (await phdApiClient.authentication.customerLookup(input)));
  }
};

export default customerService;

import PhdApiEndpoints from './endpoints';
import logger from '../../common/logger';
import { parseJson, post as clientHelperPost } from '../clientHelpers';
import standaloneApiClient from '../standaloneApiClient';
import StandaloneEndpoints from '../standaloneApiClient/endpoints';
import { Occasion } from '@/localization/constants';
import {
  StoreSpecificSearchException,
  transformCustomerDetails,
  transformPromotion,
  transformStoreDetail
} from './transformPHDData';
import loggingFetch from '../../common/loggingFetch';
import loggingFetchv2, {
  LoggingFetchParser
} from '../../common/loggingFetchv2';
import { StoreSearchException } from '@/services/localizationService/phdapiLocalizationService';
import telemetry from '@/telemetry';
import { getPublicRuntimeConfig } from './helpers/getPublicRuntimeConfig';
import { getUrl } from './helpers/getUrl';
import { getWithHeaders } from './helpers/getWithHeaders';
import createGuest from '@/api/phdApiClient/Authentication/createGuest';
import clearSession from '@/api/phdApiClient/ClearSession/clearSession';
import { getBalance } from '@/api/phdApiClient/LegacyGiftCards/getBalance';
import { DoesStoreOfferDealResult } from '@/services/dealService/types';
import { getCustomer } from '@/api/phdApiClient/accountManagement/myAccount/getCustomer';
import updateCustomer from '@/api/phdApiClient/accountManagement/myAccount/updateCustomer';
import addCustomerAddress from '@/api/phdApiClient/accountManagement/myAccount/addCustomerAddress';
import deleteCard from './accountManagement/myAccount/deleteCard';
import { deleteCoupon } from './accountManagement/myAccount/deleteCoupon';
import updatePromotionsContact from './accountManagement/myAccount/updatePromotionsContact';
import deleteAddress from './accountManagement/myAccount/deleteAddress';
import { escalatePrivileges } from '@/api/phdApiClient/accountManagement/escalatePrivileges';
import { updatePassword } from '@/api/phdApiClient/accountManagement/myAccount/updatePassword';
import {
  getCarryoutOrderStatus,
  getDeliveryOrderStatus
} from '@/api/phdApiClient/OrderStatus/getOrderStatus';
import {
  MARKETING_DEAL_NOT_AVAILABLE,
  MARKETING_DEAL_NOT_FOR_CARRYOUT,
  MARKETING_DEAL_NOT_FOR_DELIVERY
} from './constants';
import { PhdApiCouponCheck } from './types';
import editCustomerAddress from '@/api/phdApiClient/accountManagement/myAccount/editCustomerAddress';
import getConfig from "next/config";
import { customerLookup, customerLookupWithContents } from './Authentication/customerLookup';

interface RequestBody {
  [key: string]: any;
}

interface CustomerLoyaltyCoupon {
  code: string,
  coupon_association_key: number,
  points: number
}

export interface StoreSearchApiResult {
  found: boolean;
  results: any;
  errorCode?: string;
}

export interface DealPrivateCodeResult {
  found: boolean;
  privateCode?: string;
}

export class CustomerLoyaltyException extends Error { }

const fetchOptions = (): RequestInit => ({ credentials: 'include' });

// deprecated - create a method in base.ts
const post = async (
  body: RequestBody,
  endpoint: PhdApiEndpoints,
  headers?: any
): Promise<any> => {
  const url = getUrl(endpoint);
  const { phdApiServerEnv } = getPublicRuntimeConfig();
  return loggingFetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'x-server-env': phdApiServerEnv,
      ...headers
    },
    body: JSON.stringify(body),
    redirect: 'follow'
  }).then(parseJson);
};



// deprecated - create a method in base.ts
const get = (
  endpoint: PhdApiEndpoints,
  additionalHeaders?: any
): Promise<any> =>
  getWithHeaders(getUrl(endpoint), additionalHeaders).then(parseJson);

const getStoreDetails = async (
  endpoint: PhdApiEndpoints,
  occasion: Occasion,
  didLocalizationTokenChange: boolean,
  pathParameters: string[],
  storeToken?: string
): Promise<ClientResult<StoreDetail>> => {
  let endpointWithParameters = endpoint as string;

  pathParameters.forEach((param) => {
    endpointWithParameters = endpointWithParameters.replace('%s', param);
  });

  return getWithHeaders(getUrl(endpointWithParameters))
    .then(parseJson)
    .then(({ response, status }) => {
      if (didLocalizationTokenChange && storeToken) {
        return standaloneApiClient
          .post(
            { token: storeToken },
            StandaloneEndpoints.SAVE_LOCALIZATION_TOKEN
          )
          .then(() =>
            transformStoreDetail(response, status, storeToken, occasion)
          );
      }
      return transformStoreDetail(response, status, storeToken ?? '', occasion);
    })
    .catch((error) => {
      telemetry.addNoticeError(error, {
        endpoint: getUrl(endpointWithParameters)
      });
      logger.withoutTelemetry.error(error.message);
      return { error: true };
    });
};

const getStoreInfo = async (store: string) : Promise<{ isDragontail: boolean; error: boolean }> => {
  const endpointWithStoreNumber = PhdApiEndpoints.GET_STORE.replace('%s', store);
  return getWithHeaders(getUrl(endpointWithStoreNumber))
    .then(parseJson)
    .then(({ response, status }) => {
      if (status !== 200) {
        throw new StoreSpecificSearchException(
          `Unexpected response for store-specific information (status: ${status})`
        );
      }
      return { isDragontail: response.dragontail as boolean ?? false, error: false };
    })
    .catch((error) => {
      telemetry.addNoticeError(error, {
        endpoint: getUrl(endpointWithStoreNumber)
      });
      logger.withoutTelemetry.error(error.message);
      return { isDragontail: false, error: true };
    });
};

const getCustomerInfo = (): Promise<CustomerDetails> => {
  const endpoint = PhdApiEndpoints.GET_CUSTOMER_DETAILS;
  return fetchWithHeaders(endpoint).then(transformCustomerDetails);
};

const postCustomerPromotions = (
  email: string,
  postalCode: string
): Promise<boolean> => {
  const includeEmailOptIn = true;
  const endpoint = PhdApiEndpoints.POST_CUSTOMER_PROMOTIONS;
  const { phdApiServerEnv } = getPublicRuntimeConfig();
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': phdApiServerEnv
  };
  const url = getUrl(endpoint);

  const promotionBody = transformPromotion(
    email,
    postalCode,
    includeEmailOptIn
  );

  return clientHelperPost(url, headers, promotionBody)
    .then((response) => {
      if (response.status !== 204) {
        const error = new Error(
          `Unexpected response: ${response.statusText} (status: ${response.status})`
        );
        logger.withoutTelemetry.error(error.message);
        throw error;
      }
      return true;
    })
    .catch((error) => {
      telemetry.addNoticeError(error, { endpoint });
      return false;
    });
};

function isStatus2xxOr4xx(status: number, response: any) {
  return (
    status === 200 ||
    (status === 404 &&
      (response.possible_matches || response.message === 'dta exception'))
  );
}

const findStores = async (body: any): Promise<StoreSearchApiResult> => {
  const endpoint = PhdApiEndpoints.STORE_SEARCH;

  return post(body, endpoint).then(
    ({ status, response }: { status: number; response: any }) => {
      if (status !== 200 && status !== 404) {
        const error = new StoreSearchException(
          `Unexpected response for store status, ${status}`
        );
        telemetry.addNoticeError(error, { endpoint });
        logger.withoutTelemetry.error(error.message);
        throw error;
      }
      
      return {
        found: status === 200 && response?.length !== 0,
        errorCode: response?.error_code,
        results: isStatus2xxOr4xx(status, response) && response
      };
    }
  );
};

type HeaderOptions = { localization: string };
const fetchWithHeaders = (endpoint: string, headers?: HeaderOptions, withCredentials = true) => {
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  return getWithHeaders(
    getUrl(endpoint),
    {
        'x-server-env': phdApiServerEnv,
        ...headers,
    },
    withCredentials ? fetchOptions() : undefined
  ).then(parseJson);
};

const isLocal = getConfig()?.publicRuntimeConfig.ENVIRONMENT_NAME === 'local';

const fetchWithParser = <T>(
  endpoint: string,
  localizationToken: string,
  parser: LoggingFetchParser<T>,
  xServerEnv?: string
) => {
  const { phdApiServerEnv } = getPublicRuntimeConfig();
  let headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': xServerEnv ?? phdApiServerEnv,
    localization: localizationToken
  };
  headers = isLocal ? Object.assign(headers, { "User-Agent": 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4270.0 Safari/537.36' }) : headers;
  return loggingFetchv2(
    {
      requestUrl: getUrl(endpoint),
      options: {
        headers
      }
    },
    parser
  );
};

const getStoreDealEndpoint = (storeID: string, dealCode: string): string => {
  return `/stores/${storeID}/discounts/marketing_code/${dealCode}`;
};

const getPrivateCode = async (
  storeID: string,
  id: string,
  localizationToken: string,
  xServerEnv?: string
): Promise<DealPrivateCodeResult> => {
  const endpoint = getStoreDealEndpoint(storeID, id);
  const parser = async (response: Response): Promise<DealPrivateCodeResult> => {
    if (response.status !== 200 && response.status !== 404) {
      throw new Error(
        `Unexpected response when getting private deal id, ${response.status}`
      );
    }

    const body = await response.json();
    if (response.status === 404) {
      const error = new Error(
        `Deal ${id} does not exist on store ${storeID}. Error code: ${body.error_code}`
      );
      telemetry.addNoticeError(error, { endpoint });
      return { found: false };
    }

    const privateCode = body.master_discount_id
      ? body.master_discount_id
      : body.discount_id;
    if (!privateCode) {
      throw new Error(`Invalid private deal id received: ${id}`);
    }

    return { found: true, privateCode };
  };

  return fetchWithParser<DealPrivateCodeResult>(endpoint, localizationToken, parser, xServerEnv);
};

const checkCouponCode = async (
  storeID: string,
  coupon: string,
  localizationToken: string
): Promise<DoesStoreOfferDealResult> => {
  const endpoint = getStoreDealEndpoint(storeID, coupon);

  return fetchWithHeaders(endpoint, { localization: localizationToken }, false).then(
    ({ status, response }: { status: number; response: PhdApiCouponCheck }) => {
      if (status === 200 && !response.error_code) {
        return { storeOffersDeal: true };
      }

      const errorMessage = response.message;

      if (errorMessage === MARKETING_DEAL_NOT_AVAILABLE) {
        return { storeOffersDeal: false };
      } else if (errorMessage === MARKETING_DEAL_NOT_FOR_DELIVERY) {
        return { storeOffersDeal: true, switchOccasion: true };
      } else if (errorMessage === MARKETING_DEAL_NOT_FOR_CARRYOUT) {
        return { storeOffersDeal: true, switchOccasion: true };
      }

      const error = new Error(
        `Unexpected response for checking coupon code for store, ${status}`
      );
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      throw error;
    }
  );
};

export default {
  post, // deprecated - create a method in base.ts
  get, // deprecated - create a method in base.ts
  getStoreDetails,
  getStoreInfo,
  getCustomerInfo,
  findStores,
  getPrivateCode,
  checkCouponCode,
  clearSession,
  authentication: {
    createGuest,
    customerLookup,
    customerLookupWithContents
  },
  myAccount: {
    getCustomer,
    updateCustomer,
    addCustomerAddress,
    deleteCard,
    deleteCoupon,
    updatePromotionsContact,
    deleteAddress,
    escalatePrivileges,
    updatePassword,
    editCustomerAddress
  },
  getLegacyGiftCardBalance: getBalance,
  postCustomerPromotions,
  getDeliveryOrderStatus,
  getCarryoutOrderStatus
};

import { Action, combineReducers } from 'redux';
import {
  CARRYOUT_SEARCH_SUCCESS,
  CLOSE_LOCALIZATION_RAIL,
  CLOSE_MODAL,
  DELIVERY_SEARCH_SUCCESS,
  HIDE_LOADING_BAR,
  HIDE_LOADING_INDICATOR,
  HIDE_LOADING_QUERY,
  LOAD_SAVED_ADDRESSES,
  OPEN_LOCALIZATION_RAIL,
  OPEN_MODAL,
  SET_OCCASION_SEARCH_ADDRESS,
  SHOW_LOADING_BAR,
  SHOW_LOADING_INDICATOR,
  SHOW_LOADING_QUERY,
  SHOW_SEARCH_IN_RAIL,
  SWITCH_TO_CARRYOUT,
  SWITCH_TO_DELIVERY,
  USING_SAVED_ADDRESSES,
  SHOULD_PREFILL_INPUT,
  RESET_PREFILL_INPUT
} from './actionTypes';
import { CarryoutSearchSuccess, DeliverySearchSuccess, RouteToAfterLocalization } from './localizeActions';
import { Occasion } from './constants';
import { LOGOUT } from '@/common/logout/actions';
import { AUTH_TOKEN_EXPIRED, AUTH_TOKEN_REFRESH_FAILURE } from '@/auth/actions';

const localizationRail = (
  state: {
    isOpen: boolean,
    routeTo: string | undefined
    handleAfterLocalizing?: () => void
  } = {
    isOpen: false,
    routeTo: undefined,
    handleAfterLocalizing: undefined
  },
  action: Action
) => {
  switch (action.type) {
    case OPEN_LOCALIZATION_RAIL: {
      return {
        isOpen: true,
        routeTo: (action as RouteToAfterLocalization).routeTo,
        handleAfterLocalizing: (action as RouteToAfterLocalization).handleAfterLocalizing
      };
    }
    case CLOSE_LOCALIZATION_RAIL: {
      return {
        isOpen: false,
        routeTo: undefined,
        handleAfterLocalizing: undefined
      };
    }
    default: {
      return state;
    }
  }
};

export const rail = combineReducers({
  options: localizationRail
});

const occasion = (state: string = Occasion.CARRYOUT, action: Action) => {
  switch (action.type) {
    case CLOSE_LOCALIZATION_RAIL:
    case SWITCH_TO_CARRYOUT: {
      return Occasion.CARRYOUT;
    }
    case SWITCH_TO_DELIVERY: {
      return Occasion.DELIVERY;
    }
    default: {
      return state;
    }
  }
};

const searchInRail = (state = true, action: Action) => {
  switch (action.type) {
    case CARRYOUT_SEARCH_SUCCESS:
    case DELIVERY_SEARCH_SUCCESS: {
      return false;
    }
    case CLOSE_LOCALIZATION_RAIL:
    case SHOW_SEARCH_IN_RAIL: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const previousSearchDetails = (
  state: CarryoutSearchDetails | DeliveryAddress = {}, action: Action
) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      const fullAddress = action as DeliverySearchSuccess;
      return {
        address: fullAddress.address,
        address2: fullAddress.address2,
        city: fullAddress.city,
        state: fullAddress.state,
        zipcode: fullAddress.zipcode
      } as DeliveryAddress;
    }
    case CARRYOUT_SEARCH_SUCCESS: {
      const {
        zipcode, city, state
      } = action as CarryoutSearchSuccess;
      return {
        zipcode, city, state
      } as CarryoutSearchDetails;
    }
    case CLOSE_LOCALIZATION_RAIL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const isSearchingIndicator = (
  state = false, action: Action
) => {
  switch (action.type) {
    case SHOW_LOADING_INDICATOR:
      return true;
    case HIDE_LOADING_INDICATOR:
      return false;
    default:
      return state;
  }
};

const modalOpen = (state = false, action: Action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return true;
    }
    case CLOSE_MODAL: {
      return false;
    }
    default:
      return state;
  }
};

const modalData = (state: ModalContent | {} = {}, action: Action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return (action as any).data;
    }
    default:
      return state;
  }
};

const modal = combineReducers({
  open: modalOpen,
  data: modalData
});

const suggestedDeliveryAddresses = (
  state: Array<DeliveryAddress> | null = null,
  action: DeliverySearchSuccess
) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      return action.deliverySearchResults.deliveryAddresses || null;
    }
    default:
      return state;
  }
};

const defaultSavedAddresses: SavedAddress[] = [];
const savedAddresses = (state: SavedAddress[] = defaultSavedAddresses, action: Action) => {
  switch (action.type) {
    case LOAD_SAVED_ADDRESSES: {
      return (action as any).addresses;
    }
    case AUTH_TOKEN_REFRESH_FAILURE:
    case AUTH_TOKEN_EXPIRED:
    case LOGOUT: {
      return defaultSavedAddresses;
    }
    default:
      return state;
  }
};

const currentlyLocalizedAddress = (state: DeliveryAddress | {} = {}, action: Action) => {
  switch (action.type) {
    case SET_OCCASION_SEARCH_ADDRESS: {
      const fullAddress = action as DeliverySearchSuccess;
      return {
        address: fullAddress.address,
        address2: fullAddress.address2,
        city: fullAddress.city,
        state: fullAddress.state,
        zipcode: fullAddress.zipcode,
        lat: fullAddress?.lat,
        lng: fullAddress?.lng
      } as DeliveryAddress;
    }
    default:
      return state;
  }
};

const defaultUseSavedAddresses = false;
const useSavedAddresses = (state: boolean = defaultUseSavedAddresses, action: Action) => {
  switch (action.type) {
    case USING_SAVED_ADDRESSES:
      return (action as any).usingSavedAddresses;
    case LOAD_SAVED_ADDRESSES:
      return (action as any).addresses.length > 0;
    case AUTH_TOKEN_REFRESH_FAILURE:
    case AUTH_TOKEN_EXPIRED:
    case LOGOUT:
      return defaultUseSavedAddresses;
    default:
      return state;
  }
};

const initShowLoadingBar = false;
const showLoadingBar = (state: boolean = initShowLoadingBar, action: Action) => {
  switch (action.type) {
    case SHOW_LOADING_BAR:
      return true
    case HIDE_LOADING_BAR:
      return false
    default:
      return state
  }
}

const initHasLoadingQuery = false;
const hasLoadingQuery = (state: boolean = initHasLoadingQuery, action: Action) => {
  switch (action.type) {
    case SHOW_LOADING_QUERY:
      return true
    case HIDE_LOADING_QUERY:
      return false
    default:
      return state
  }
}

const initShouldPrefillInput = false;
const shouldPrefillInput = (state: boolean = initShouldPrefillInput, action: Action) => {
  switch(action.type) {
    case SHOULD_PREFILL_INPUT:
      return true;
    case RESET_PREFILL_INPUT:
      return false;  
    default:
      return state;
  }
};


const localizationUi = combineReducers({
  rail,
  searchInRail,
  occasion,
  previousSearchDetails,
  isSearchingIndicator,
  modal,
  savedAddresses,
  suggestedDeliveryAddresses,
  currentlyLocalizedAddress,
  useSavedAddresses,
  showLoadingBar,
  hasLoadingQuery,
  shouldPrefillInput
});

export default localizationUi;
